<template>
  <div v-if="showprofile">
    <div class="text-right">
      <base-button @click="handleShowTable">Kembali ke Tabel</base-button>
    </div>
    <RegProfileInformation :profile="profile" />
  </div>
  <div v-if="showtable">
    <div>
      <div
        class="
          col-12
          d-flex
          justify-content-center justify-content-sm-between
          flex-wrap
        "
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div>
          <div class="btn-group" role="group">
            <base-button type="primary" size="sm">
              <span class="btn-inner--icon"
                ><i class="ni ni-collection"></i
              ></span>
              <span class="btn-inner--text">Export</span>
            </base-button>
            <base-button type="danger" size="sm" v-if="deleteSelectedButton" @click="handleDeleteSelected()">
              <span class="btn-inner--icon"
                ><i class="fa fa-trash-alt"></i
              ></span>
              <span class="btn-inner--text">Remove</span>
            </base-button>
          </div>
          &nbsp;
          <el-input
            type="search"
            class="mb-3"
            clearable
            prefix-icon="el-icon-search"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </div>
      </div>

      <el-table
        :data="queriedData"
        row-key="id"
        header-row-class-name="thead-light"
        @selection-change="selectionChange"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
        >
        </el-table-column>
        <el-table-column min-width="200px" align="left"  label="Data Anak">
          <template v-slot:default="props">
            <ul
              class="text-xs text-muted"
              v-for="data_reg in props.row.childrens"
              v-bind:key="data_reg.reg_id"
              style="margin-left: -25px"
            >
              <li style="margin-bottom: -20px">
                <base-button
                  @click="handleShowProfile(data_reg.reg_id, data_reg)"
                  size="sm"
                  outline
                  type="secondary"
                  >{{ data_reg.reg_fullname }}</base-button
                >
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" align="right" label="Actions">
          <template v-slot:default="props">
            <div class="d-flex">
              <div class="btn-group" role="group">
                <base-button
                  @click="handleUpdate(props.$index, props.row)"
                  class="btn-link"
                  type="success"
                  size="sm"
                  icon
                >
                  <!--<i class="text-white ni ni-fat-remove"></i>-->
                  <i class="text-white fa fa-pen"></i>
                </base-button>
                <base-button
                  @click="handleDelete(props.$index, props.row)"
                  class="remove btn-link"
                  type="danger"
                  size="sm"
                  icon
                >
                  <!--<i class="text-white ni ni-fat-remove"></i>-->
                  <i class="text-white fa fa-trash-alt"></i>
                </base-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <br />
    <div
      class="
        col-12
        d-flex
        justify-content-center justify-content-sm-between
        flex-wrap
      "
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>
    <div>
      <small
        ><small v-if="delete_action_warning_message">
          <hr />
          <i>
            Catatan log error:
            <ul class="text-danger">
              <li
                v-for="listerror in delete_action_warning_message"
                v-bind:key="listerror"
              >
                <span v-html="listerror"></span>
              </li>
            </ul>
          </i> </small
      ></small>
    </div>

    <!-- Modal Form Input -->
    <modal v-model:show="modals.classic">
      <Form @submit.prevent="onSubmit" :validation-schema="schema">
        <h6 class="modal-title">Modal Form</h6>
        <hr />
        <div>
          <GenInput
            formtype="default"
            :forminit="form"
            :fieldspecial="fieldspecial"
            :fieldtitle="fieldtitle"
          />
        </div>
        <hr />

        <base-button type="default" :loading="isLoading" native-type="submit"
          >Simpan Data</base-button
        >
        <base-button type="link" class="ml-auto" @click="modals.classic = false"
          >Close</base-button
        >
      </Form>
    </modal>
    <!-- Modal Form Input -->

    <modal v-model:show="modals.classic2">
      <Form @submit.prevent="onDeleteSubmit">
        <input type="hidden" v-model="form2.id" />
        <input type="hidden" v-model="form2.idarray" />
        <h6 class="modal-title">Modal Form Delete</h6>
        <hr />
        <div>
          <div class="text-sm text-muted text-center">
            Anda ingin menghapus data Orang Tua
            <h3>{{ deleteLabelRow }}</h3>
          </div>
          <div class="form-group">
            <h3 class="text-primary">#Opsi Mode Hapus Data</h3>
            <div class="col-md-12">
              <el-select
                placeholder="Pilih Mode Hapus"
                v-model="form2.delete_mode"
                filterable
              >
                <el-option
                  v-for="select2 in deleteModeSelect"
                  class="select-danger"
                  :value="select2.value"
                  :label="select2.label"
                  :key="select2.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <hr />
          <base-button type="danger" :loading="isLoading" native-type="submit"
            >Hapus Data</base-button
          >
          <base-button
            type="link"
            class="ml-auto"
            @click="modals.classic2 = false"
            >Close</base-button
          >
        </div>
      </Form>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import GenInput from "@/components/Generate/GenInput";
import RegProfileInformation from "@/components/Datas/RegProfileInformation";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";

export default {
  name: "FamiliesTables",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    GenInput,
    RegProfileInformation,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      selectedRows: [],
      deleteSelectedButton: false,
      modals: {
        classic: false,
      },
      urlread: "/api/family/read",
      urlstore: "/api/family/store",
      urldelete: "/api/family/delete",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      //propsToSearch: ["fullname", "role", "email", "relation_desc"],
      propsToSearch: ["fullname","relation_desc","role"],
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "fullname",
          label: "Nama Lengkap",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "relation_desc",
          label: "Hubungan",
          minWidth: 90,
          sortable: true,
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "role",
          label: "Role",
          minWidth: 100,
          sortable: true,
        },
      ],

      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      loadingTable: "",
      displayError: "",

      form: {
        id: "",
        email: "",
        role: "",
        active: "",
        password: "",
      },
      form2: {
        id: "",
        idarray: "",
        delete_mode: 1,
        delete_mode_extended: false,
      },
      fieldspecial: {
        id: {
          input_type: "hidden",
          label: "",
        },
        role: {
          input_type: "options",
          label: "Role",
          selects: [
            {
              value: "Parentguard",
              name: "Parentguard",
            },
          ],
        },
        active: {
          input_type: "options",
          label: "Active",
          selects: [
            {
              value: 1,
              name: "Yes",
            },
            {
              value: 0,
              name: "No",
            },
          ],
        },
      },
      fieldtitle: {
        email: {
          title: "Data Orang Tua",
        },
      },
      isLoading: false,
      profile: [],
      showtable: true,
      showprofile: false,

      deleteModeSelect: [
        { value: 1, label: "Hapus Data Orang Tua beserta siswa didalamnya" },
        { value: 2, label: "Hanya hapus data siswa saja" },
      ],
      deleteLabelRow: "",
      delete_action_warning_message: "",
    };
  },
  created() {
    this.getTables();
  },
  methods: {
    overlayLoading() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      return loading;
    },
    async getTables() {
      try {
        this.loadingTable = "loading data...";
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data;
        this.tableData = this.datas;
        //console.log(this.datas);

        this.loadingTable = "";
      } catch (error) {
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
        this.loadingTable = "";
      }
    },
    handleShowProfile(index, row) {
      this.showtable = false;
      this.showprofile = true;

      this.profile = row;
      //console.log(this.profile);
    },
    handleShowTable() {
      this.showtable = true;
      this.showprofile = false;
    },
    loadForm() {
      this.modals.classic = true;
      this.clearForm();
    },
    clearForm() {
      for (let x in this.form) {
        this.form[x] = "";
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: this.urlstore,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire("Good job!", "Data berhasil disimpan!", "success");
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    async handleUpdate(index, row) {
      console.log("handleupdate");
      /*
      // open modal
      this.modals.classic = true;
      // clear form
      this.clearForm();
      try {
        const response = await axios({
          method: "get",
          url: this.urlread + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let data = response.data;
        for (let x in this.form) {
          this.form[x] = data[x];
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
      */
    },
    handleDeleteSelected() {
      this.modals.classic2 = true;
      // display label
      this.deleteLabelRow = "Selected data: "+this.selectedRows.length;
      this.form2.id = 0;
      this.form2.idarray = this.selectedRows;
    },
    handleDelete(index, row) {
      this.modals.classic2 = true;
      // display label
      this.deleteLabelRow = row.fullname;
      this.form2.id = row.id;
      this.form2.idarray = '';
    },
    async onDeleteSubmit() {
      let loading = this.overlayLoading();
      this.isLoading = true;

      try {
        const response = await axios({
          method: "post",
          url: this.urldelete + "/" + this.form2.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form2,
        });
        let data = response.data;
        this.delete_action_warning_message = data.warning_message;
        console.log(data);

        // close loader
        loading.close();
        this.isLoading = false;
        this.modals.classic2 = false;

        swal.fire('Success!', data.message, 'success');

        // load table
        this.getTables();
      } catch (error) {
        console.log(error);
        loading.close();
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    handleDeleteOld(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          html: `You won't be able to revert this! <b>` + row.name + `</b>`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              html: `You deleted <b>${row.name}</b>`,
            });
          }
        });
    },
    async deleteRow(row) {
      try {
        const response = await axios({
          method: "delete",
          url: this.urldelete + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        console.log(response.data);
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
      this.deleteSelectedButton = true;
      if(selectedRows.length > 0) {
        this.deleteSelectedButton = true;
      }else{
        this.deleteSelectedButton = false;
      }
      console.log(this.selectedRows);
    },
  },
};
</script>

<style>
</style>