import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Alternative from "../views/Dashboard/AlternativeDashboard.vue";
import Widgets from "../views/Widgets.vue";
import Charts from "../views/Charts.vue";
import Calendar from "../views/Calendar/Calendar.vue";

// Pages
import Pricing from "../views/Pages/Pricing.vue";
import Login from "../views/Pages/Login.vue";
import Register from "../views/Pages/Register.vue";
import Lock from "../views/Pages/Lock.vue";
import Logout from "../views/Pages/Logout.vue";
import Profile from "../views/Pages/UserProfile.vue";
import Timeline from "../views/Pages/TimeLinePage.vue";

// Components pages
import Buttons from "../views/Components/Buttons.vue";
import Cards from "../views/Components/Cards.vue";
import Grid from "../views/Components/GridSystem.vue";
import Notifications from "../views/Components/Notifications.vue";
import Icons from "../views/Components/Icons.vue";
import Typography from "../views/Components/Typography.vue";

// Tables pages
import RegularTables from "../views/Tables/RegularTables.vue";
import SortableTables from "../views/Tables/SortableTables.vue";
import PaginatedTables from "../views/Tables/PaginatedTables.vue";

// Registration
import RegRegisteredTables from "../views/Tables/RegRegisteredTables.vue";
import GenerateNISTables from "../views/Tables/GenerateNISTables.vue";
import Infreglinks from "../views/Infreglinks.vue";
import Import from "../views/Import.vue";

/*
import RegActiveTables from "../views/Tables/RegActiveTables.vue";
import RegPassedTables from "../views/Tables/RegPassedTables.vue";
import RegCandidateTables from "../views/Tables/RegCandidateTables.vue";
*/

// Academic
import Ppdbs from "../views/Ppdbs.vue";
import StudentTables from "../views/Tables/StudentTables.vue";

// Finance
import Typays from "../views/Typays.vue";

// Maps pages
import GoogleMaps from "../views/Maps/GoogleMaps.vue";
import VectorMaps from "../views/Maps/VectorMaps.vue";

// Forms pages
import FormElements from "../views/Forms/FormElements.vue";
import FormComponents from "../views/Forms/FormComponents.vue";
import FormValidation from "../views/Forms/FormValidation.vue";

// master data
import SettingApps from "../views/SettingApps.vue";
import Units from "../views/Units.vue";
import Tas from "../views/Tas.vue";

// employee
import Permission from "../views/Permission.vue";
import Roles from "../views/Roles.vue";
import Users from "../views/Users.vue";

// student
import Classroom from "../views/Classroom.vue";
import ActiveStudent from "../views/ActiveStudent.vue";
import NonActiveStudent from "../views/NonActiveStudent.vue";
import Family from "../views/Family.vue";

let masterdataMenu = {
  path: "/masterdata",
  component: DashboardLayout,
  redirect: "/masterdata/settingapps",
  name: "Settings",
  children: [
    {
      path: "settingapps",
      name: "SettingApps",
      components: { default: SettingApps },
    },
    {
      path: "units",
      name: "Units",
      components: { default: Units },
    },
    {
      path: "ta",
      name: "Tas",
      components: { default: Tas },
    },
    {
      path: "users",
      name: "Users",
      components: { default: Users },
    }, 
  ],
};

let employeeMenu = {
  path: "/employee",
  component: DashboardLayout,
  redirect: "/employee/users",
  name: "Employee",
  children: [
    {
      path: "permission",
      name: "Permission",
      components: { default: Permission },
    },
    {
      path: "roles",
      name: "Roles",
      components: { default: Roles },
    },
    {
      path: "users",
      name: "Users",
      components: { default: Users },
    }, 
  ],
};

let PSBMenu = {
  path: "/psb",
  component: DashboardLayout,
  redirect: "/psb/reg_registered",
  name: "PSB",
  children: [   
    {
      path: "ppdb",
      name: "Ppdbs",
      components: { default: Ppdbs },
    }, 
    {
      path: "reg_registered",
      name: "RegRegisteredTables",
      components: { default: RegRegisteredTables },
    },
    {
      path: "generate_nis",
      name: "GenerateNISTables",
      components: { default: GenerateNISTables },
    },
    {
      path: "infreglink",
      name: "Infreglinks",
      components: { default: Infreglinks },
    },
    {
      path: "import",
      name: "Import",
      components: { default: Import },
    },
    /*
    {
      path: "reg_active",
      name: "RegActiveTables",
      components: { default: RegActiveTables },
    },
    {
      path: "reg_passed",
      name: "RegPassedTables",
      components: { default: RegPassedTables },
    },
    {
      path: "reg_candidate",
      name: "RegCandidateTables",
      components: { default: RegCandidateTables },
    },
    */
  ],
};

let studentMenu = {
  path: "/student",
  component: DashboardLayout,
  redirect: "/student/classroom",
  name: "Student",
  children: [    
    {
      path: "classroom",
      name: "Classroom",
      components: { default: Classroom },
    },
    {
      path: "active_students",
      name: "ActiveStudent",
      components: { default: ActiveStudent },
    },
    {
      path: "nonactive_students",
      name: "NonActiveStudent",
      components: { default: NonActiveStudent },
    },
    {
      path: "families",
      name: "Family",
      components: { default: Family },
    },
  ],
};

let academicMenu = {
  path: "/academic",
  component: DashboardLayout,
  redirect: "/academic/students",
  name: "Academic",
  children: [    
    {
      path: "students",
      name: "StudentTables",
      components: { default: StudentTables },
    },
  ],
};

let financeMenu = {
  path: "/finance",
  component: DashboardLayout,
  redirect: "/finance/typays",
  name: "Finance",
  children: [
    {
      path: "typays",
      name: "Typays",
      components: { default: Typays },
    }, 
  ],
};

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "cards",
      name: "Cards",
      components: { default: Cards },
    },
    {
      path: "grid-system",
      name: "Grid",
      components: { default: Grid },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/tables/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Tables",
      components: { default: RegularTables },
    },
    {
      path: "sortable",
      name: "Sortable",
      components: { default: SortableTables },
    },
    {
      path: "paginated",
      name: "Paginated",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  name: "Maps",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "vector",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Forms elements",
      components: { default: FormElements },
    },
    {
      path: "components",
      name: "Forms Components",
      components: { default: FormComponents },
    },
    {
      path: "validation",
      name: "Forms Validation",
      components: { default: FormValidation },
    },
  ],
};

const routes = [
  {
    path: "/dashboard",
    redirect: "/dashboard",
    component: DashboardLayout,
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/alternative",
        name: "Alternative",
        components: { default: Alternative },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
      {
        path: "/charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "/calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "/pages/user",
        name: "Profile",
        components: { default: Profile },
      },
      {
        path: "/pages/timeline",
        name: "Timeline",
        components: { default: Timeline },
      },
    ],
  },
  {
    path: "/",
    redirect: "/login",
    component: AuthLayout,
    children: [
      {
        path: "/pricing",
        name: "Pricing",
        components: { default: Pricing },
      },
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "Register",
        components: { default: Register },
      },
      {
        path: "/lock",
        name: "Lock",
        components: { default: Lock },
      },
      {
        path: "/logout",
        name: "Logout",
        components: { default: Logout },
      },
    ],
  },
  masterdataMenu,
  employeeMenu,
  PSBMenu,
  studentMenu,
  academicMenu,
  financeMenu,
  componentsMenu,
  tablesMenu,
  mapsMenu,
  formsMenu,
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
