<template>
  <div class="content">
    <Auth />
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Infreglinks</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Infreglinks</h3>
            <p class="text-sm mb-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </template>
          <div>
            <div
              class="
                col-12
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <InfreglinksTables />
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/Auth/Auth";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import InfreglinksTables from "@/components/Datas/InfreglinksTables";

export default {
  name: "Infreglinks",
  components: {
    Auth,
    RouteBreadCrumb,
    InfreglinksTables,
  },
};
</script>

<style>
</style>