<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Welcome!</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form @submit="onSubmit" :validation-schema="schema">
                <base-input
                  alternative
                  name="email"
                  addon-left-icon="ni ni-email-83"
                  placeholder="Email"
                >
                </base-input>

                <base-input
                  alternative
                  name="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                >
                </base-input>

                <div class="text-center">
                  <base-button
                    type="primary"
                    :loading="isLoading"
                    native-type="submit"
                    class="my-4"
                    >Sign in</base-button
                  >
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/login" class="text-light"
                ><small>Forgot password?</small></router-link
              >
            </div>
            <div class="col-6 text-right">
              <router-link to="/login" class="text-light"
                ><small>Create new account</small></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import { Form } from "vee-validate";
import * as Yup from "yup";

export default {
  components: {
    Form,
  },
  data() {
    return {
      isLoading: false,
      model: {
        email: "",
        password: "",
      },
    };
  },
  created() {
    localStorage.setItem("token", "");
    localStorage.setItem("refresh_token", "");
    localStorage.setItem("auth", "");
    localStorage.setItem("payload", "");   
  },
  methods: {
    async onSubmit(values) {
      try {
        this.isLoading = true;
        //let dataForm = JSON.stringify(values, null, 2);

        const response = await axios.post("/api/login", values);
        // save response
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        let dataAuth = JSON.stringify(response.data.auth);
        let dataPayload = JSON.stringify(response.data.payload);
        localStorage.setItem("auth", dataAuth);
        localStorage.setItem("payload", dataPayload);        

        //redirect
        this.$router.push({ path: "/dashboard" });

        /*
      const schema = Yup.object().shape({
        email: Yup.string().email().required().label("The Email"),
        password: Yup.string().min(5).required().label("The Password"),      
      });
      */
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },
  /*
  setup() {
    function onSubmit(values) {
      let dataForm = JSON.stringify(values, null, 2);
      alert(dataForm);
    }

    const schema = Yup.object().shape({
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(5).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  },
  */
};
</script>
