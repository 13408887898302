<template>
  <div>
    <div>
      <div
        class="
          col-12
          d-flex
          justify-content-center justify-content-sm-between
          flex-wrap
        "
      >
        <h3 class="mb-0">Filter Data</h3>
        <table>
          <tr>
            <td>
              <el-select
                placeholder="Pilih Tahun Ajar"
                v-model="form.id_ta"
                filterable
              >
                <el-option
                  v-for="option in selects.tas"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
              &nbsp;
              <el-select placeholder="Pilih Unit" v-model="form.id_unit">
                <el-option value="0" label="-- All Unit --"
                  >-- All Unit --</el-option
                >
                <el-option
                  v-for="option2 in selects.units"
                  class="select-danger"
                  :value="option2.value"
                  :label="option2.label"
                  :key="option2.label"
                >
                </el-option>
              </el-select>
              &nbsp;
            </td>
            <td width="10px"></td>
            <td>
              <base-button
                type="default"
                @click="handleFormFilter"
                :loading="isLoading"
                >Filter Data</base-button
              >
            </td>
          </tr>
        </table>
      </div>
      <hr />
      <div
        class="
          col-12
          d-flex
          justify-content-center justify-content-sm-between
          flex-wrap
        "
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <div>
          <div class="btn-group" role="group">
            <base-button type="default" size="sm" @click="loadForm">
              <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
              <span class="btn-inner--text">Add Data</span>
            </base-button>
            <base-button type="primary" size="sm">
              <span class="btn-inner--icon"
                ><i class="ni ni-collection"></i
              ></span>
              <span class="btn-inner--text">Export</span>
            </base-button>
          </div>
          &nbsp;
          <el-input
            type="search"
            class="mb-3"
            clearable
            prefix-icon="el-icon-search"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </div>
      </div>

      <el-table
        v-loading="loadingTable"
        :data="queriedData"
        row-key="id"
        header-row-class-name="thead-light"
        @selection-change="selectionChange"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
        >
        </el-table-column>

        <el-table-column min-width="150px" label="Jadwal">
          <template v-slot:default="props">
            <span v-html="props.row.label_start_end_reg"></span>
          </template>
        </el-table-column>

        <el-table-column min-width="100px" label="Tipe">
          <template v-slot:default="props">
            {{ props.row.type }}
          </template>
        </el-table-column>

        <el-table-column min-width="100px" label="Exclude last level">
          <template v-slot:default="props">
            <span v-if="props.row.exclude_last_unitlevel == 1">
              <badge type="success">Yes</badge>
            </span>
            <span v-if="props.row.exclude_last_unitlevel == 0">
              <badge type="danger">No</badge>
            </span>
          </template>
        </el-table-column>

        <el-table-column min-width="100px" label="Status">
          <template v-slot:default="props">
            <span v-if="props.row.status == 'Open'">
              <badge type="success">{{ props.row.status }}</badge>
            </span>
            <span v-if="props.row.status == 'Close'">
              <badge type="danger">{{ props.row.status }}</badge>
            </span>
          </template>
        </el-table-column>

        <el-table-column min-width="150px" label="Pendaftar">
          <template v-slot:default="props">
            <span v-if="props.row.total_reg > 0">
              <base-button
                size="sm"
                @click="
                  pushlink(props.row.id_ta, props.row.id_unit, props.row.id)
                "
                outline
                type="primary"
                >{{ props.row.total_reg }} pendaftar</base-button
              >
            </span>
            <span v-else><i>null</i></span>
          </template>
        </el-table-column>

        <el-table-column min-width="180px" align="right" label="Actions">
          <template v-slot:default="props">
            <div class="d-flex">
              <div class="btn-group" role="group">
                <base-button
                  @click="handleUpdate(props.$index, props.row)"
                  class="btn-link"
                  type="success"
                  size="sm"
                  icon
                >
                  <!--<i class="text-white ni ni-fat-remove"></i>-->
                  <i class="text-white fa fa-pen"></i>
                </base-button>
                <base-button
                  @click="handleDelete(props.$index, props.row)"
                  class="remove btn-link"
                  type="danger"
                  size="sm"
                  icon
                >
                  <!--<i class="text-white ni ni-fat-remove"></i>-->
                  <i class="text-white fa fa-trash-alt"></i>
                </base-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      class="
        col-12
        d-flex
        justify-content-center justify-content-sm-between
        flex-wrap
      "
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>
    <div>
      <small
        ><small v-if="delete_action_warning_message">
          <hr />
          <i>
            Catatan log error:
            <ul class="text-danger">
              <li
                v-for="listerror in delete_action_warning_message"
                v-bind:key="listerror"
              >
                <span v-html="listerror"></span>
              </li>
            </ul>
          </i> </small
      ></small>
    </div>

    <!-- Modal Form Input -->
    <modal v-model:show="modals.classic" size="xl">
      <Form @submit.prevent="onSubmit" :validation-schema="schema">
        <h6 class="modal-title">Modal Form</h6>
        <hr />
        <div style="height: 500px; overflow-y: scroll">
          <h3 class="text-primary">#Data PPDB</h3>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-md-12 col-form-label form-control-label"
                  >Tahun Ajaran</label
                >
                <div class="col-md-12">
                  <select v-model="form.id_ta" class="form-control">
                    <option
                      v-for="select in taselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-md-12 col-form-label form-control-label"
                  >Unit</label
                >
                <div class="col-md-12">
                  <select v-model="form.id_unit" class="form-control">
                    <option
                      v-for="select in unitselect"
                      v-bind:key="select.value"
                      v-bind:value="select.value"
                    >
                      {{ select.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <GenInput
            formtype="default"
            :forminit="form"
            :fieldspecial="fieldspecial"
            :fieldtitle="fieldtitle"
            row="col-md-4"
          />

          <div class="form-group">
            <label class="col-md-12 col-form-label form-control-label"
              >Catatan Administrasi dan Jadwal</label
            >
            <div class="col-md-12">
              <editor
                v-model="form.schedule_requirements_notes"
                api-key="tx7p8fppx84bog5b3vabg2nh7m74incxuyxnlcvjkugmpsmc"
                :init="{
                  height: 500,
                  menubar: false,

                  plugins: [
                    'advlist autolink lists link image table charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
                            table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | \
           bullist numlist outdent indent | removeformat | help',
                }"
              />
            </div>
          </div>
        </div>
        <hr />

        <base-button type="default" :loading="isLoading" native-type="submit"
          >Simpan Data</base-button
        >
        <base-button type="link" class="ml-auto" @click="modals.classic = false"
          >Close</base-button
        >
      </Form>
    </modal>
    <!-- Modal Form Input -->

    <modal v-model:show="modals.classic2">
      <Form @submit.prevent="onDeleteSubmit">
        <input type="hidden" v-model="form2.id" />
        <h6 class="modal-title">Modal Form Delete</h6>
        <hr />
        <div>
          <div class="text-sm text-muted text-center">
            Anda ingin menghapus data PPDB
            <h3>{{ deleteLabelRow }}</h3>
          </div>
          <div class="form-group">
            <h3 class="text-primary">#Opsi Mode Hapus Data</h3>
            <div class="col-md-12">
              <el-select
                placeholder="Pilih Mode Hapus"
                v-model="form2.delete_mode"
                filterable
              >
                <el-option
                  v-for="select2 in deleteModeSelect"
                  class="select-danger"
                  :value="select2.value"
                  :label="select2.label"
                  :key="select2.label"
                >
                </el-option>
              </el-select>
            </div>
            <br />
            <div class="form-group">
              <div class="col-md-12">
                <base-checkbox
                  class="mb-3"
                  v-model="form2.delete_mode_extended"
                >
                  Hapus Data Orang Tua juga dan yang berkaitan
                </base-checkbox>
              </div>
            </div>
          </div>
          <hr />
          <base-button type="danger" :loading="isLoading" native-type="submit"
            >Hapus Data</base-button
          >
          <base-button
            type="link"
            class="ml-auto"
            @click="modals.classic2 = false"
            >Close</base-button
          >
        </div>
      </Form>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import GenInput from "@/components/Generate/GenInput";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "PpdbsTables",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    GenInput,
    Editor,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlread: "/api/ppdbs/read",
      urlstore: "/api/ppdbs/store",
      urldelete: "/api/ppdbs/delete",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "label_start_end_reg",
        "ta_desc",
        "desc",
        "source",
        "period",
      ],
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "period",
          label: "Periode",
          minWidth: 100,
        },
        {
          prop: "desc",
          label: "Deskripsi",
          minWidth: 100,
        },
        {
          prop: "source",
          label: "Source",
          minWidth: 100,
        },
      ],

      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      loadingTable: false,
      displayError: "",
      isLoading: false,
      fullPage: true,

      selects: {
        tas: [],
        units: [],
      },

      form: {
        id: "",
        id_ta: "",
        id_unit: "",
        desc: "",
        period: "",
        start_reg: "",
        end_reg: "",
        active: "",
        type: "",
        exclude_last_unitlevel: "",
        source: "",
        selection: "",
        status: "",
        leader: "",
        co_leader: "",
        passdate: "",
        startdate: "",
        nosk: "",
        notes: "",
        schedule_requirements_notes: "",
      },
      form2: {
        id: "",
        delete_mode: 1,
        delete_mode_extended: false,
      },
      fieldspecial: {
        id: {
          input_type: "hidden",
          label: "",
        },
        notes: {
          input_type: "textarea",
          label: "Catatan",
        },
        desc: {
          input_type: "text",
          label: "Deskripsi",
        },
        leader: {
          input_type: "text",
          label: "Kepala Sekolah",
        },
        co_leader: {
          input_type: "text",
          label: "Ketua Panitia",
        },
        nosk: {
          input_type: "text",
          label: "Nomor SK",
        },
        id_ta: {
          input_type: "exclude",
          label: "",
        },
        id_unit: {
          input_type: "exclude",
          label: "",
        },
        start_reg: {
          input_type: "datepicker",
          label: "Tanggal Mulai",
        },
        end_reg: {
          input_type: "datepicker",
          label: "Tanggal Berakhir",
        },
        active: {
          input_type: "options",
          label: "Active",
          selects: [
            {
              value: "Active",
              name: "Active",
            },
            {
              value: "Registered",
              name: "Registered",
            },
          ],
        },
        type: {
          input_type: "options",
          label: "Type",
          selects: [
            {
              value: "Baru",
              name: "Baru",
            },
            {
              value: "Pindahan",
              name: "Pindahan",
            },
          ],
        },
        exclude_last_unitlevel: {
          input_type: "options",
          label: "Exclude Last Level",
          selects: [
            {
              value: 1,
              name: "Yes",
            },
            {
              value: 0,
              name: "No",
            },
          ],
        },
        source: {
          input_type: "options",
          label: "Source",
          selects: [
            {
              value: "Internal",
              name: "Internal",
            },
            {
              value: "External",
              name: "External",
            },
          ],
        },
        selection: {
          input_type: "options",
          label: "Seleksi",
          selects: [
            {
              value: "Test",
              name: "Test",
            },
            {
              value: "Non Test",
              name: "Non Test",
            },
          ],
        },
        status: {
          input_type: "options",
          label: "Status",
          selects: [
            {
              value: "Open",
              name: "Open",
            },
            {
              value: "Close",
              name: "Close",
            },
          ],
        },
        passdate: {
          input_type: "datepicker",
          label: "Pengumuman Tanggal Kelulusan",
        },
        startdate: {
          input_type: "datepicker",
          label: "Tanggal Mulai Aktif Sekolah",
        },
        schedule_requirements_notes: {
          input_type: "exclude",
          label: "",
        },
      },
      fieldtitle: [],
      taselect: [],
      unitselect: [],

      deleteModeSelect: [
        { value: 1, label: "Hapus Data PPDB beserta siswa didalamnya" },
        { value: 2, label: "Hanya hapus data siswa saja" },
      ],
      deleteLabelRow: "",
      delete_action_warning_message: "",
    };
  },
  created() {
    this.getTaSelect();
    this.getUnitSelect();
  },
  methods: {
    overlayLoading() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      return loading;
    },
    async getTaSelect() {
      try {
        const response = await axios.get("api/ta/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.taselect = response.data;
        this.selects.tas = response.data;
        let taslength = response.data.length;

        if (taslength > 0) {
          let selectedTA = taslength - 1;
          this.selects.id_ta = this.selects.tas[selectedTA].value;
          this.form.id_ta = this.selects.tas[selectedTA].value;
        }
        this.getUnitSelect();
      } catch (error) {
        console.log(error);
      }
    },
    async getUnitSelect() {
      try {
        const response = await axios.get("api/unit/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.unitselect = response.data;
        this.selects.units = response.data;

        this.getTables();
      } catch (error) {
        console.log(error);
      }
    },
    async getTables() {
      let id_ta = this.form.id_ta;
      let id_unit = 0;
      if (this.form.id_unit != "") {
        id_unit = this.form.id_unit;
      }
      //console.log(id_ta+' '+id_unit);
      try {
        this.loadingTable = true;
        const response = await axios.get(
          this.urlread + "/" + id_ta + "/" + id_unit,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.datas = response.data;
        this.tableData = this.datas;
        console.log(this.datas);

        this.loadingTable = false;
      } catch (error) {
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
        this.loadingTable = false;
      }
    },
    loadForm() {
      this.modals.classic = true;
      this.clearForm();
    },
    clearForm() {
      for (let x in this.form) {
        if (x != "id_ta" && x != "id_unit") {
          this.form[x] = "";
          //console.log(x);
        }
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: this.urlstore,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire("Good job!", "Data berhasil disimpan!", "success");
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    async handleUpdate(index, row) {
      // open modal
      this.modals.classic = true;
      // clear form
      this.clearForm();
      try {
        const response = await axios({
          method: "get",
          url: this.urlread + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let data = response.data;
        for (let x in this.form) {
          this.form[x] = data[x];
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    handleDelete(index, row) {
      this.modals.classic2 = true;
      // display label
      this.deleteLabelRow = row.desc;
      this.form2.id = row.id;
    },
    async onDeleteSubmit() {
      let loading = this.overlayLoading();
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: this.urldelete + "/" + this.form2.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form2,
        });
        let data = response.data;
        this.delete_action_warning_message = data.warning_message;
        console.log(data);

        // close loader
        loading.close();
        this.isLoading = false;
        this.modals.classic2 = false;

        // load table
        this.getTables();
      } catch (error) {
        console.log(error);
        loading.close();
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    handleDeleteOld(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          html: `You won't be able to revert this! <b>` + row.desc + `</b>`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              html: `You deleted <b>${row.desc}</b>`,
            });
          }
        });
    },
    async deleteRow(row) {
      try {
        const response = await axios({
          method: "delete",
          url: this.urldelete + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        console.log(response.data);
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    pushlink(id_ta, id_unit, id_ppdb) {
      let url = this.$router.push({
        name: "RegRegisteredTables",
        params: { id_ta: id_ta, id_unit: id_unit, id_ppdb: id_ppdb },
      });
      console.log(url);
    },
    handleFormFilter() {
      this.getTables();
    },
  },
};
</script>

<style>
</style>