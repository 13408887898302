<template>
  <div class="content">
    <Auth />
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            Units
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Units</h3>
          </template>
          <div>
            <div
              class="
                col-12
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <!-- Start Tabs Content -->
              <ul class="nav nav-pills" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="data01-tab"
                    data-toggle="tab"
                    href="#data01"
                    role="tab"
                    aria-controls="data01"
                    aria-selected="true"
                    >Units</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="data02-tab"
                    data-toggle="tab"
                    href="#data02"
                    role="tab"
                    aria-controls="data02"
                    >Unit Levels</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="data01"
                  role="tabpanel"
                  aria-labelledby="data01-tab"
                >
                  <div class="card">
                    <div class="card-body">
                      <br />
                      <UnitsTables />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="data02"
                  role="tabpanel"
                  aria-labelledby="data02-tab"
                >
                  <div class="card">
                    <div class="card-body">
                      <br />
                      <UnitlevelsTables/>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Tabs Content -->
              
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/Auth/Auth";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import UnitsTables from "@/components/Datas/UnitsTables";
import UnitlevelsTables from "@/components/Datas/UnitlevelsTables";

export default {
  name: "Units",
  components: {
    Auth,
    RouteBreadCrumb,
    UnitsTables,
    UnitlevelsTables
  },
};
</script>

<style>
</style>