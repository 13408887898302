<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar :logo="logoadmin">
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboards',
            icon: 'ni ni-shop text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'Dashboard', path: '/dashboard' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'MasterData',
            icon: 'fas fa-air-freshener text-orange',
          }"
        >
          <sidebar-item
            :link="{ name: 'Setting Apps', path: '/masterdata/settingapps' }"
          />
          <sidebar-item
            :link="{ name: 'Unit Sekolah', path: '/masterdata/units' }"
          />
          <sidebar-item
            :link="{ name: 'Tahun Ajaran', path: '/masterdata/ta' }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'ManajemenPegawai',
            icon: 'fas fa-user-shield text-danger',
          }"
        >
          <sidebar-item
            :link="{ name: 'Permission', path: '/employee/permission' }"
          />
          <sidebar-item :link="{ name: 'Role', path: '/employee/roles' }" />
          <sidebar-item :link="{ name: 'User', path: '/employee/users' }" />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'AdministrasiPSB',
            icon: 'fas fa-address-book text-info',
          }"
        >
          <sidebar-item
            :link="{ name: 'Jadwal Pelaksanaan', path: '/psb/ppdb' }"
          />
          <sidebar-item
            :link="{
              name: 'Pendaftar Baru',
              path: '/psb/reg_registered',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Generate NIS',
              path: '/psb/generate_nis',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Infreglink',
              path: '/psb/infreglink',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Import',
              path: '/psb/import',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'AdministrasiSiswa',
            icon: 'fas fa-user-friends text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'Manajemen Kelas', path: '/student/classroom' }"
          />
          <sidebar-item
            :link="{
              name: 'Data Siswa Aktif',
              path: '/student/active_students',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Data Siswa Tidak Aktif',
              path: '/student/nonactive_students',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Data Orang Tua',
              path: '/student/families',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Akademik',
            icon: 'fas fa-school text-success',
          }"
        >
          <sidebar-item :link="{ name: 'Referensi Pembelajaran', path: '#' }" />
          <sidebar-item :link="{ name: 'Daftar KD/Indikator', path: '#' }" />
          <sidebar-item :link="{ name: 'Jadwal Pembelajaran', path: '#' }" />
          <sidebar-item :link="{ name: 'Administrasi KBM', path: '#' }" />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'AdmCatatanAnekdotal',
            icon: 'fas fa-sticky-note text-pink',
          }"
        >
          <sidebar-item
            :link="{ name: 'Referensi Catatan Anekdotal', path: '#' }"
          />
          <sidebar-item
            :link="{ name: 'Daftar Catatan ANekdotal', path: '#' }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'AkademikPenilaian',
            icon: 'fas fa-journal-whills text-default',
          }"
        >
          <sidebar-item :link="{ name: 'ReferensiPenilaian', path: '#' }">
            <sidebar-item :link="{ name: 'Jenis Penilaian', path: '#' }" />
            <sidebar-item
              :link="{ name: 'Penilaian KI-1 dan KI-2', path: '#' }"
            />
            <sidebar-item
              :link="{ name: 'Formula Penilaian KI-3 dan KI-4', path: '#' }"
            />
            <sidebar-item :link="{ name: 'Konversi Penilaian', path: '#' }" />
            <sidebar-item
              :link="{ name: 'Administrasi Penilaian', path: '#' }"
            />
          </sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'AdministrasiEkskul',
            icon: 'fas fa-running text-info',
          }"
        >
          <sidebar-item :link="{ name: 'Daftar Ekskul', path: '#' }" />
          <sidebar-item :link="{ name: 'Jadwal Ekskul', path: '#' }" />
          <sidebar-item :link="{ name: 'Administrasi Peserta', path: '#' }" />
          <sidebar-item :link="{ name: 'Penilaian', path: '#' }" />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'AdministrasiPerwalian',
            icon: 'fas fa-user-tie text-primary',
          }"
        >
          <sidebar-item :link="{ name: 'Administrasi Kelas', path: '#' }" />
          <sidebar-item :link="{ name: 'Absensi Bulanan', path: '#' }" />
          <sidebar-item :link="{ name: 'Proses Nilai KI-1', path: '#' }" />
          <sidebar-item :link="{ name: 'Proses Nilai KI-2', path: '#' }" />
          <sidebar-item :link="{ name: 'Ledger Penilaian', path: '#' }" />
          <sidebar-item :link="{ name: 'Catatan Akademik', path: '#' }" />
          <sidebar-item :link="{ name: 'Catatan Prestasi', path: '#' }" />
          <sidebar-item :link="{ name: 'Print Out Raport', path: '#' }" />
          <sidebar-item :link="{ name: 'Preview Raport Online', path: '#' }" />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Keuangan',
            icon: 'fas fa-money-bill-wave text-orange',
          }"
        >
          <sidebar-item
            :link="{ name: 'Regulasi Biaya', path: '/finance/typays' }"
          />
          <sidebar-item
            :link="{ name: 'Setting Akses Akademik Siswa', path: '#' }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Laporan',
            icon: 'fas fa-chart-bar text-success',
          }"
        >
          <sidebar-item :link="{ name: 'Laporan PSB', path: '#' }" />
          <sidebar-item :link="{ name: 'Laporan Akademik', path: '#' }" />
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Perpustakaan',
            icon: 'fas fa-book text-info',
          }"
        >
          <sidebar-item :link="{ name: 'Link Perpustakaan', path: '#' }" />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'InfoPengumuman',
            icon: 'fas fa-newspaper text-danger',
          }"
        >
          <sidebar-item :link="{ name: 'Jenis Agenda Akademik', path: '#' }" />
          <sidebar-item :link="{ name: 'Agenda Akademik', path: '#' }" />
          <sidebar-item :link="{ name: 'Info dan Pengumuman', path: '#' }" />
        </sidebar-item>
      </template>
<!-- 
      <template v-slot:links-after>
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Documentation</h6>
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation"
              target="_blank"
            >
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/avatars.html"
              target="_blank"
            >
              <i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Components</span>
            </a>
          </li>
        </ul>
      </template> -->

      <!--
      <template v-slot:links-after>
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Documentation</h6>

        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation"
              target="_blank"
            >
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/foundation/colors.html"
              target="_blank"
            >
              <i class="ni ni-palette"></i>
              <span class="nav-link-text">Foundation</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/avatars.html"
              target="_blank"
            >
              <i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Components</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/vue-argon-dashboard-pro/documentation/components/plugin_datepicker.html"
              target="_blank"
            >
              <i class="ni ni-chart-pie-35"></i>
              <span class="nav-link-text">Plugins</span>
            </a>
          </li>
        </ul>
      </template>
      -->
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
import axios from "axios";

/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      logoadmin:"",
      basePath: axios.defaults.baseURL,
      basePathFolderImageLogo: "/uploads/settingapps/",
    }
  },
  created() {
    this.getSettingApps();
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    async getSettingApps() {
      try {
        const response = await axios.get("/api/settingapps/read", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data.data;
        let logo_admin = this.datas.logo_admin;
        if(logo_admin != '') {
          let urlLogoadmin = this.basePath+this.basePathFolderImageLogo+logo_admin;
          this.logoadmin = urlLogoadmin;
          //console.log(this.logoadmin)
        }
        /*
        if(this.datas.length > 0) {
          let logo = this.datas.logo;
          console.log(logo);
        }
        */
        //console.log(this.datas);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
