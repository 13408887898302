<template>
  <div>
    <div>
      <div
        class="
          col-12
          d-flex
          justify-content-center justify-content-sm-between
          flex-wrap
        "
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div>
          <div class="btn-group" role="group">
            <base-button type="primary" size="sm">
              <span class="btn-inner--icon"
                ><i class="ni ni-collection"></i
              ></span>
              <span class="btn-inner--text">Export</span>
            </base-button>
          </div>
          &nbsp;
          <el-input
            type="search"
            class="mb-3"
            clearable
            prefix-icon="el-icon-search"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </div>
      </div>

      <el-table
        :data="queriedData"
        row-key="id"
        header-row-class-name="thead-light"
        @selection-change="selectionChange"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
        >
        </el-table-column>
        
      </el-table>
    </div>
    <br />
    <div
      class="
        col-12
        d-flex
        justify-content-center justify-content-sm-between
        flex-wrap
      "
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>

    
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import GenInput from "@/components/Generate/GenInput";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";

export default {
  name: "TasTables",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    GenInput,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      modals: {
        classic: false,
      },
      urlread: "/api/infreglink/read",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["email"],
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "fullname",
          label: "Nama Pendaftar",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "data_students",
          label: "Data Siswa",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "infreg_name",
          label: "Sumber Informasi",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "infreglink_fullname",
          label: "Nama Sumber",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "infreglink_cellphone",
          label: "No HP Sumber",
          minWidth: 100,
          sortable: true,
        },
        
        
      ],

      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      loadingTable: "",
      displayError: "",

      
      isLoading: false,
    };
  },
  created() {
    this.getTables();
  },
  methods: {
    async getTables() {
      try {
        this.loadingTable = "loading data...";
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data;
        this.tableData = this.datas;
        //console.log(this.datas);

        this.loadingTable = "";
      } catch (error) {
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
        this.loadingTable = "";
      }
    },
    
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
  },
};
</script>

<style>
</style>