import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

//axios.defaults.baseURL = "http://localhost:8000";
//axios.defaults.baseURL = "https://api.ypii.net"
//axios.defaults.baseURL = "http://api-siakads.test"
//axios.defaults.baseURL = "http://localhost:8000/git-api-siakads"
axios.defaults.baseURL = "https://api.annisaa-izada.sch.id"
const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");