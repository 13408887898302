<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Lock screen</h1>
              <p class="text-lead text-white">Better to be safe than sorry.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card card-profile bg-secondary mt-5">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <i
                    class="ni ni-circle-08"
                    style="font-size: 100px; margin-top: -25px"
                  ></i>
                </div>
              </div>
            </div>
            <div class="card-body pt-3 px-5">
              <div class="text-center mb-4">
                <h3>Lock user!</h3>
              </div>
              <p class="lead text-center">Token expired, please login</p>
              <div class="text-center">
                <!--<base-button type="primary" :to="{ path: '/login' }" class="mt-2">Go to Login</base-button>-->
                <router-link to="/login" class="btn btn-primary">
                  <span class="nav-link-inner--text">Go to Login</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "lock",
  data() {
    return {
      model: {
        password: "",
      },
    };
  },
};
</script>
<style></style>
