<template>
  <div class="content">
    <Auth />
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Calon Siswa Baru</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          v-if="showprofile"
        >
          <div>
            <RegProfileInformation :profile="profile" />
          </div>

          <template v-slot:footer>
            <hr />
            <div
              style="padding: 0px 20px 20px 20px"
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div>
                <base-button @click="handleShowTable"
                  >Kembali ke Tabel</base-button
                >
              </div>
            </div>
          </template>
        </card>

        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          v-if="showtable"
        >
          <template v-slot:header>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <h3 class="mb-0">Tabel Pendaftaran Siswa Baru</h3>
              <table>
                <tr>
                  <td>
                    <el-select
                      placeholder="Pilih Tahun Ajar"
                      v-model="selects.id_ta"
                      @change="handleSelectFilter"
                      filterable
                    >
                      <el-option
                        v-for="option in selects.tas"
                        class="select-danger"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label"
                      >
                      </el-option>
                    </el-select>
                    &nbsp;
                    <el-select
                      placeholder="Pilih Unit"
                      v-model="selects.id_unit"
                      @change="handleSelectFilter"
                      filterable
                    >
                      <el-option value="0" label="-- All Unit --"
                        >-- All Unit --</el-option
                      >
                      <el-option
                        v-for="option2 in selects.units"
                        class="select-danger"
                        :value="option2.value"
                        :label="option2.label"
                        :key="option2.label"
                      >
                      </el-option>
                    </el-select>
                  </td>
                  <td width="10px"></td>
                  <td>
                    <base-button
                      type="default"
                      @click="handleFormFilter"
                      :loading="isLoadingFormFilter"
                      >Filter Data</base-button
                    >
                  </td>
                </tr>
              </table>
            </div>
          </template>
          <div>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <h4>{{ selects.ppdbselected_label }}</h4>
              <div>
                <div class="btn-group" role="group">
                  <base-button
                    type="danger"
                    size="sm"
                    v-if="showCreateNISButton"
                    @click.prevent="loadForm"
                  >
                    <span class="btn-inner--icon"
                      ><i class="ni ni-circle-08"></i
                    ></span>
                    <span class="btn-inner--text">Create NIS</span>
                  </base-button>
                  <base-dropdown
                    title-classes="btn btn-primary btn-sm btn-group-right"
                  >
                    <template v-slot:title
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-collection"></i></span
                      ><span class="btn-inner--text" style="padding-left: 2px"
                        >Export</span
                      ></template
                    >
                    <a class="dropdown-item" @click="exportPDF">PDF</a>
                    <a class="dropdown-item" @click="exportEXCEL">EXCEL</a>
                  </base-dropdown>
                </div>
                &nbsp;
                <el-input
                  type="search"
                  class="mb-3"
                  clearable
                  prefix-icon="el-icon-search"
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </div>
            </div>
            <el-table
              v-loading="loadingTable"
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column min-width="100px" label="Status NIS">
                <template v-slot:default="props">
                  <span v-if="props.row.already_create_nis == null">
                    <small class="text-muted">preview</small>
                  </span>
                  <span v-else>
                    <badge type="success">created</badge>
                  </span>
                </template>
              </el-table-column>

              <el-table-column min-width="100px" align="right" label="Actions">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div class="btn-group" role="group">
                      <base-button
                        class="remove btn-link"
                        type="default"
                        size="sm"
                        @click="handleShowProfile(props.$index, props.row)"
                        icon
                      >
                        <i class="text-white fa fa-search-plus"></i>
                      </base-button>
                      <!--
                      <base-button
                        class="remove"
                        type="success"
                        size="sm"
                        @click="
                          loadFormCreateManualNIS(props.$index, props.row)
                        "
                        icon
                        v-if="props.row.already_create_nis == null"
                      >
                        create
                      </base-button>
                      -->
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pl-4">
              <small
                ><i class="text-danger">{{ displayError }}</i></small
              >
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
            <!-- Modal Form Input -->
            <modal v-model:show="modals.classic2">
              <Form @submit.prevent="onSubmitCreateManualNIS">
                <div>
                  <h3 class="text-primary">#Create Manual NIS</h3>
                  <div class="form-group">
                    <label class="col-md-12 col-form-label form-control-label">
                      <div class="text-left" v-html="selects.datareg"></div>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="col-md-12 col-form-label form-control-label"
                      >Create Manual NIS</label
                    >
                    <div class="col-md-12">
                      <input
                        type="text"
                        v-model="selects.nis"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <hr />
                  <base-button
                    type="default"
                    :loading="isLoadingCreateManualNIS"
                    native-type="submit"
                    >Submit Data</base-button
                  >
                  <base-button
                    type="link"
                    class="ml-auto"
                    @click="modals.classic2 = false"
                    >Close</base-button
                  >
                </div>
              </Form>
            </modal>
            <!-- Modal Form Input -->

            <!-- Modal Form Input -->
            <modal v-model:show="modals.classic">
              <Form @submit.prevent="onSubmit">
                <h6 class="modal-title">Modal Form</h6>
                <hr style="margin-top: 15px" />
                <div style="margin-top: -10px">
                  <table>
                    <tr>
                      <td style="width: 170px">Tahun Ajaran</td>
                      <td style="width: 10px">:</td>
                      <td><b>Ganjil 2022/2023</b></td>
                    </tr>
                    <tr>
                      <td>Unit Sekolah</td>
                      <td>:</td>
                      <td><b>KB/TK Sekolah</b></td>
                    </tr>
                    <tr>
                      <td>Total Siswa</td>
                      <td>:</td>
                      <td>
                        <b>{{ total_reg }} siswa</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Kunci Tahun Ajaran</td>
                      <td>:</td>
                      <td>
                        <span v-if="ppdb_lock == false">
                          <base-button
                            size="sm"
                            type="success"
                            :loading="isLoadingPPDBLock"
                            @click="SubmitPPDBLock"
                            >Belum terkunci, Kunci Sekarang!</base-button
                          >
                        </span>
                        <span v-if="ppdb_lock == true">
                          <base-button size="sm" type="dark" disabled
                            >Sudah terkunci!</base-button
                          >
                        </span>
                      </td>
                    </tr>
                  </table>
                  <hr />

                  <span v-if="total_reg > 0">
                    <span v-if="button_generate_nis == true">
                      <base-button
                        type="primary"
                        :loading="isLoadingGenerateNIS"
                        native-type="submit"
                        >Generate NIS</base-button
                      >
                    </span>
                  </span>
                  <base-button
                    type="link"
                    class="ml-auto"
                    @click="modals.classic = false"
                    >Close</base-button
                  >
                </div>
              </Form>
            </modal>
            <!-- Modal Form Input -->
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Auth from "@/components/Auth/Auth";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import RegProfileInformation from "@/components/Datas/RegProfileInformation";

export default {
  name: "RegRegisteredTables",
  components: {
    Auth,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    RegProfileInformation,
    Modal,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      showtable: true,
      showprofile: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["noreg", "fullname"],
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "noreg",
          label: "Noreg",
          minWidth: 100,
        },
        {
          prop: "unitlevel_name",
          label: "Unit Level",
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "fullname",
          label: "Nama Lengkap",
          minWidth: 150,
          sortable: true,
        },
        {
          prop: "previewnis",
          label: "Preview NIS",
          minWidth: 100,
          sortable: true,
        },
      ],
      regs: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      loadingTable: false,
      displayError: "",
      showCreateNISButton: false,
      selects: {
        id_reg: "",
        id_ta: "",
        id_unit: "",
        tas: [],
        units: [],
        datareg: "",
        nis: "",
      },
      profile: [],
      basic: [],
      ppdb_lock: false,
      button_generate_nis: false,
      total_reg: 0,
      isLoadingGenerateNIS: false,
      isLoadingFormFilter: false,
      isLoadingPPDBLock: false,
      isLoadingCreateManualNIS: false,
      count: 0,
      inputmanualnis: "",
      basePath: axios.defaults.baseURL,
    };
  },
  mounted() {
    this.TASelect();
    this.UnitSelect();
  },
  methods: {
    overlayLoading() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      return loading;
    },
    checkParam() {
      if (
        this.$route.params.id_ta !== undefined &&
        this.$route.params.id_unit !== undefined &&
        this.$route.params.id_ppdb !== undefined
      ) {
        this.selects.id_ta = parseInt(this.$route.params.id_ta);
        this.selects.id_unit = parseInt(this.$route.params.id_unit);
      }
    },
    async TASelect() {
      try {
        const response = await axios.get("/api/ta/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.tas = response.data;

        let taslength = response.data.length;
        if (taslength > 0) {
          let selectedTA = taslength - 1;
          this.selects.id_ta = this.selects.tas[selectedTA].value;

          this.checkParam();

          this.getRegsRegistered();
        }
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    async UnitSelect() {
      try {
        const response = await axios.get("/api/unit/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.units = response.data;
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    handleFormFilter() {
      this.getRegsRegistered();
    },
    async getRegsRegistered() {
      try {
        let id_ta = this.selects.id_ta;
        let id_unit = 0;
        if (this.selects.id_unit != "") {
          id_unit = this.selects.id_unit;
        }

        let paramurl = id_ta + "/" + id_unit;
        let url = "/api/reg/generatenis/" + paramurl;

        this.loadingTable = true;
        this.isLoadingFormFilter = true;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.regs = response.data;
        //console.log(regs);
        this.tableData = this.regs;
        if (this.regs != "" && this.selects.id_unit != "") {
          this.handleShowCreateNISButton();
        }
        //console.log(this.regs);

        this.loadingTable = false;
        this.isLoadingFormFilter = false;
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
        this.loadingTable = false;
        this.isLoadingFormFilter = false;
      }
    },
    async GetBasicDataBeforeCreateNIS() {
      this.count++;
      console.log(this.count);
      let loading = this.overlayLoading();
      try {
        const response = await axios.get(
          "/api/reg/getbasicdatabeforecreatenis/" +
            this.selects.id_ta +
            "/" +
            this.selects.id_unit,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.basicdata = response.data;

        if (this.basicdata.ppdblock == null) {
          this.ppdb_lock = false;
        } else {
          this.ppdb_lock = true;
        }

        if (this.basicdata.generate_nis == false) {
          this.button_generate_nis = false;
        } else {
          this.button_generate_nis = true;
        }

        this.total_reg = this.basicdata.regs.length;
        console.log(this.basicdata);
        loading.close();
      } catch (error) {
        loading.close();
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    loadForm() {
      this.modals.classic = true;
      this.GetBasicDataBeforeCreateNIS();
    },
    handleShowCreateNISButton() {
      this.showCreateNISButton = true;
    },
    async SubmitPPDBLock() {
      this.isLoadingPPDBLock = true;
      try {
        const response = await axios.get(
          "/api/reg/submitppdblock/" +
            this.selects.id_ta +
            "/" +
            this.selects.id_unit,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
          this.isLoadingPPDBLock = false;
        } else if (response.data.status == "success") {
          this.GetBasicDataBeforeCreateNIS();
          this.isLoadingPPDBLock = false;
        }
        //console.log(this.basicdata);
      } catch (error) {
        this.isLoadingPPDBLock = false;
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    handleHideCreateNISButton() {
      this.showCreateNISButton = false;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    handleShowProfile(index, row) {
      this.showtable = false;
      this.showprofile = true;

      this.profile = row;
      //console.log(this.profile);
    },
    handleShowTable() {
      this.showtable = true;
      this.showprofile = false;
    },
    handleSelectFilter() {
      this.handleHideCreateNISButton();
    },
    async onSubmit() {
      this.isLoadingGenerateNIS = true;
      try {
        const response = await axios({
          method: "post",
          url: "/api/reg/storegeneratenis",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            regs: JSON.stringify(this.regs),
            id_ta: this.selects.id_ta,
            id_unit: this.selects.id_unit,
          },
        });
        console.log(response.data);
        this.isLoadingGenerateNIS = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire("Good job!", "Generate NIS berhasil dibuat!", "success");
          // load table
          this.getRegsRegistered();
          // close modal
          this.modals.classic = false;
        }
      } catch (error) {
        console.log(error);
        this.isLoadingGenerateNIS = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    loadFormCreateManualNIS(index, row) {
      this.modals.classic2 = true;
      this.isLoadingCreateManualNIS = false;

      this.selects.id_reg = row.id;
      this.selects.datareg =
        row.fullname +
        " (" +
        row.unitlevel_name +
        ")<br />Preview NIS:" +
        row.previewnis;

      this.selects.nis = row.previewnis;
    },
    async onSubmitCreateManualNIS() {
      this.isLoadingCreateManualNIS = true;
      try {
        const response = await axios({
          method: "post",
          url: "/api/reg/createmanualnis/",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            id_reg: this.selects.id_reg,
            nis: this.selects.nis,
          },
        });
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire("Good job!", "NIS berhasil dibuat!", "success");
          // load table
          this.getRegsRegistered();
          // close modal
          this.modals.classic = false;
        }
        this.isLoadingCreateManualNIS = false;
      } catch (error) {
        console.log(error);
        Swal.fire("Error!", error.message, "error");
        this.isLoadingCreateManualNIS = false;
      }
    },
    async exportPDF() {
      let loading = this.overlayLoading();
      let id_ta = this.selects.id_ta;
      let id_unit = 0;
      if (this.selects.id_unit != "") {
        id_unit = this.selects.id_unit;
      }
      let random = Math.random().toString(16).slice(2);

      let url =
        "/api/reg/exportpdfgeneratenis/" + id_ta + "/" + id_unit + "/" + random;

      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
          loading.close();
        } else {
          loading.close();
          //Swal.fire("Good job!", response.data.message, "success");
          let urltofile = response.data.urltofile;
          //window.location.href = urltofile;
          window.open(urltofile);
          
          //loading.close();
        }
      } catch (error) {
        console.log(error);
        Swal.fire("Error!", error.message, "error");
        loading.close();
      }
    },
    async exportEXCEL() {
      let loading = this.overlayLoading();
      let id_ta = this.selects.id_ta;
      let id_unit = 0;
      if (this.selects.id_unit != "") {
        id_unit = this.selects.id_unit;
      }
      let random = Math.random().toString(16).slice(2);

      let url =
        "/api/reg/exportexcelgeneratenis/" + id_ta + "/" + id_unit + "/" + random;

      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
          loading.close();
        } else {
          Swal.fire("Good job!", response.data.message, "success");
          let urltofile = response.data.urltofile;
          window.location.href = urltofile;
          loading.close();
        }
      } catch (error) {
        console.log(error);
        Swal.fire("Error!", error.message, "error");
        loading.close();
      }
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
