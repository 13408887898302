<template>
  <div>
    <div>
      <div
        class="
          col-12
          d-flex
          justify-content-center justify-content-sm-between
          flex-wrap
        "
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div>
          <div class="btn-group" role="group">
            <base-button type="default" size="sm" @click="loadForm">
              <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
              <span class="btn-inner--text">Add Data</span>
            </base-button>
            <base-button type="primary" size="sm">
              <span class="btn-inner--icon"
                ><i class="ni ni-collection"></i
              ></span>
              <span class="btn-inner--text">Export</span>
            </base-button>
          </div>
          &nbsp;
          <el-input
            type="search"
            class="mb-3"
            clearable
            prefix-icon="el-icon-search"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </div>
      </div>

      <el-table
        :data="queriedData"
        row-key="id"
        header-row-class-name="thead-light"
        @selection-change="selectionChange"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
        >
        </el-table-column>
        <el-table-column min-width="180px" align="right" label="Actions">
          <template v-slot:default="props">
            <div class="d-flex">
              <div class="btn-group" role="group">
                <base-button
                  @click="handleUpdate(props.$index, props.row)"
                  class="btn-link"
                  type="success"
                  size="sm"
                  icon
                >
                  <!--<i class="text-white ni ni-fat-remove"></i>-->
                  <i class="text-white fa fa-pen"></i>
                </base-button>
                <base-button
                  @click="handleDelete(props.$index, props.row)"
                  class="remove btn-link"
                  type="danger"
                  size="sm"
                  icon
                >
                  <!--<i class="text-white ni ni-fat-remove"></i>-->
                  <i class="text-white fa fa-trash-alt"></i>
                </base-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <br />
    <div
      class="
        col-12
        d-flex
        justify-content-center justify-content-sm-between
        flex-wrap
      "
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>

    <!-- Modal Form Input -->
    <modal v-model:show="modals.classic">
      <Form @submit.prevent="onSubmit" :validation-schema="schema">
        <h6 class="modal-title">Modal Form</h6>
        <hr />
        <div>
          <GenInput
            formtype="default"
            :forminit="form"
            :fieldspecial="fieldspecial"
            :fieldtitle="fieldtitle"
          />
        </div>
        <hr />

        <base-button type="default" :loading="isLoading" native-type="submit"
          >Simpan Data</base-button
        >
        <base-button type="link" class="ml-auto" @click="modals.classic = false"
          >Close</base-button
        >
      </Form>
    </modal>
    <!-- Modal Form Input -->
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import GenInput from "@/components/Generate/GenInput";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import swal from "sweetalert2";

export default {
  name: "TypaysTables",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    GenInput,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      modals: {
        classic: false,
      },
      urlread: "/api/typay/read",
      urlstore: "/api/typay/store",
      urldelete: "/api/typay/delete",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name"],
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "name",
          label: "Jenis Pembayaran",
          minWidth: 200,
          sortable: true,
        },
        {
          prop: "action",
          label: "Action",
          minWidth: 100,
          sortable: true,
        },
      ],

      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      loadingTable: "",
      displayError: "",

      form: {
        id: "",
        name: "",
        action: "",
      },
      fieldspecial: {
        id: {
          input_type: "hidden",
          label: "",
        },
        name: {
          input_type: "text",
          label: "Jenis Pembayaran",
        },
        action: {
          input_type: "options",
          label: "Action",
          selects: [
            {
              value: 'Registration',
              name: 'Registration',
            },
            {
              value: 'Pass Registration Test',
              name: 'Pass Registration Test',
            },
          ],
        },
      },
      fieldtitle: {
        name: {
          title: "Data Pembayaran",
        },
      },
      isLoading: false,
    };
  },
  created() {
    this.getTables();
  },
  methods: {
    async getTables() {
      try {
        this.loadingTable = "loading data...";
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data;
        this.tableData = this.datas;
        //console.log(this.datas);

        this.loadingTable = "";
      } catch (error) {
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
        this.loadingTable = "";
      }
    },
    loadForm() {
      this.modals.classic = true;
      this.clearForm();
    },
    clearForm() {
      for (let x in this.form) {
        this.form[x] = "";
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: this.urlstore,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire("Good job!", "Data berhasil disimpan!", "success");
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    async handleUpdate(index, row) {
      // open modal
      this.modals.classic = true;
      // clear form
      this.clearForm();
      try {
        const response = await axios({
          method: "get",
          url: this.urlread + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let data = response.data;
        for (let x in this.form) {
          this.form[x] = data[x];
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    handleDelete(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          html: `You won't be able to revert this! <b>` + row.name + `</b>`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              html: `You deleted <b>${row.name}</b>`,
            });
          }
        });
    },
    async deleteRow(row) {
      try {
        const response = await axios({
          method: "delete",
          url: this.urldelete + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        console.log(response.data);
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        Swal.fire("Error!", error.message, "error");
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
  },
};
</script>

<style>
</style>