<template>
  <div class="content">
    <Auth />
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Tabel Data Siswa</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          v-if="showprofile"
        >
          <div>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div>&nbsp;</div>
              <div>
                <base-button @click="handleShowTable"
                  >Kembali ke Tabel</base-button
                >
              </div>
            </div>
            <RegProfileInformation :profile="profile" />
          </div>

          <template v-slot:footer>
            <hr />
            <div
              style="padding: 0px 20px 20px 20px"
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div>
                <base-button @click="handleShowTable"
                  >Kembali ke Tabel</base-button
                >
              </div>
            </div>
          </template>
        </card>

        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
          v-if="showtable"
        >
          <template v-slot:header>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <h3 class="mb-0"></h3>
              <table>
                <tr>
                  <td>
                    <el-select
                      placeholder="Pilih Tahun Ajar"
                      v-model="selects.id_ta"
                      @change="handleSelectPPDB"
                      filterable
                    >
                      <el-option
                        v-for="option in selects.tas"
                        class="select-danger"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label"
                        @click="selectedPPDB(option)"
                      >
                      </el-option>
                    </el-select>
                    &nbsp;
                    <el-select
                      placeholder="Pilih Unit"
                      v-model="selects.id_unit"
                      @change="handleSelectPPDB"
                      filterable
                    >
                      <el-option value="0" label="-- All Unit --"
                        >-- All Unit --</el-option
                      >
                      <el-option
                        v-for="option2 in selects.units"
                        class="select-danger"
                        :value="option2.value"
                        :label="option2.label"
                        :key="option2.label"
                      >
                      </el-option>
                    </el-select>
                    &nbsp;
                    <el-select
                      placeholder="Pilih PPDB"
                      v-model="selects.id_ppdb"
                      filterable
                    >
                      <el-option
                        value="0"
                        label="-- All PPDB --"
                        @click="selectedPPDB()"
                        >-- All PPDB --</el-option
                      >
                      <el-option
                        v-for="option3 in selects.ppdbs"
                        class="select-danger"
                        :value="option3.value"
                        :label="option3.label"
                        :key="option3.label"
                        @click="selectedPPDB(option3)"
                      >
                      </el-option>
                    </el-select>
                    &nbsp;
                    <el-select
                      placeholder="Pilih Status"
                      v-model="selects.status"
                      filterable
                    >
                      <el-option
                        v-for="option4 in selects.regstatus"
                        class="select-danger"
                        :value="option4.value"
                        :label="option4.label"
                        :key="option4.label"
                      >
                      </el-option>
                    </el-select>
                    &nbsp;
                    <el-select
                      placeholder="Pilih Data"
                      v-model="selects.import"
                      style="width:170px;"
                      filterable
                    >
                      <el-option
                        v-for="option5 in selects.regimport"
                        class="select-danger"
                        :value="option5.value"
                        :label="option5.label"
                        :key="option5.label"
                      >
                      </el-option>
                    </el-select>
                  </td>
                  <td width="10px"></td>
                  <td>
                    <base-button
                      type="default"
                      @click="handleFormFilter"
                      :loading="isLoading"
                      >Filter Data</base-button
                    >
                  </td>
                </tr>
              </table>
            </div>
          </template>
          <div>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <h4>{{ selects.ppdbselected_label }}</h4>
              <div>
                <div class="btn-group" role="group">
                  <!--
                  <base-button type="primary" size="sm">
                    <span class="btn-inner--icon"
                      ><i class="ni ni-collection"></i
                    ></span>
                    <span class="btn-inner--text">Export</span>
                  </base-button>
                  -->
                  <base-dropdown
                    title-classes="btn btn-primary btn-sm btn-group-right"
                  >
                    <template v-slot:title
                      ><span class="btn-inner--icon"
                        ><i class="ni ni-collection"></i></span
                      ><span class="btn-inner--text" style="padding-left: 2px"
                        >Export</span
                      ></template
                    >
                    <a class="dropdown-item" @click="exportPDF">PDF</a>
                    <a class="dropdown-item" @click="exportEXCEL">EXCEL</a>
                  </base-dropdown>
                </div>
                &nbsp;
                <el-input
                  type="search"
                  class="mb-3"
                  clearable
                  prefix-icon="el-icon-search"
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </div>
            </div>
            <el-table
              v-loading="loadingTable"
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              
              <el-table-column min-width="150px" label="Nama Lengkap">
                <template v-slot:default="props">
                  <span v-html="props.row.display_noreg_fullname"></span>
                </template>
              </el-table-column>

              <el-table-column min-width="150px" label="Tagihan">
                <template v-slot:default="props">
                  <span v-if="props.row.all_paidlist_status == ''">
                    <small class="text-muted"
                      ><i>Tidak ada data tagihan</i></small
                    >
                  </span>
                  <span
                    v-for="data_paidlist in props.row.all_paidlist_status"
                    v-bind:key="data_paidlist.nik"
                  >
                    <span v-if="data_paidlist.paidlist_status == 'Lunas'">
                      <el-tooltip
                        placement="top"
                        :content="data_paidlist.display_nominal_tobe_paid"
                      >
                        <badge tag="a" href="#" rounded type="success">{{
                          data_paidlist.nametypay
                        }}</badge></el-tooltip
                      >
                    </span>
                    <span v-if="data_paidlist.paidlist_status == 'Belum'">
                      <el-tooltip
                        placement="top"
                        :content="data_paidlist.display_nominal_tobe_paid"
                      >
                        <badge tag="a" href="#" rounded type="danger">{{
                          data_paidlist.nametypay
                        }}</badge>
                      </el-tooltip>
                    </span>
                    <span v-if="data_paidlist.paidlist_status == 'Cicilan'">
                      <el-tooltip
                        placement="top"
                        :content="data_paidlist.display_nominal_tobe_paid"
                      >
                        <badge tag="a" href="#" rounded type="info">{{
                          data_paidlist.nametypay
                        }}</badge>
                      </el-tooltip>
                    </span>
                  </span>
                </template>
              </el-table-column>
              <!--
              <el-table-column min-width="200px" label="Tagihan">
                <template v-slot:default="props">
                  {{ props.row.paidlist }}<br />
                </template>
              </el-table-column>
              <el-table-column min-width="100px" label="Status Tagihan">
                <template v-slot:default="props">
                  <div
                    v-if="
                      props.row.status == 'Active Student' ||
                      props.row.status == 'Conditional Active Student' ||
                      props.row.status == 'Backup Active Student'
                    "
                  >
                    <span v-if="props.row.paynote == 'Unpaid'">
                      <badge rounded type="danger" size="lg">Belum Lunas</badge>
                    </span>
                    <span v-if="props.row.paynote == 'Fullpayment'">
                      <badge rounded type="success" size="lg">Lunas</badge>
                    </span>
                    <span v-if="props.row.paynote == 'Installment'">
                      <badge rounded type="primary" size="lg">Cicilan</badge>
                    </span>
                  </div>
                  <div v-else>
                    <span v-if="props.row.paidlist_status == 'Belum Lunas'">
                      <badge rounded type="danger" size="lg">{{
                        props.row.paidlist_status
                      }}</badge>
                    </span>
                    <span v-if="props.row.paidlist_status == 'Lunas'">
                      <badge rounded type="success" size="lg">{{
                        props.row.paidlist_status
                      }}</badge>
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="150px" label="Keterangan">
                <template v-slot:default="props">
                  Tahun Ajar: {{ props.row.ta_desc }}<br />
                  Nama Unit: {{ props.row.unit_name }}<br />
                  Source: {{ props.row.ppdb_source }}<br />
                  Type: {{ props.row.ppdb_type }}<br />
                  Period: {{ props.row.ppdb_period }}<br />
                  <div v-if="props.row.status == 'Active Student'">
                    Preview NIS: <b>{{ props.row.previewnis }}</b>
                  </div>
                </template>
              </el-table-column>
              -->
              <el-table-column min-width="150px" label="Kelengkapan Data">
                <template v-slot:default="props">
                  <span
                    v-for="mandatory in props.row.mandatory_data"
                    v-bind:key="mandatory.nik"
                  >
                    <span v-if="mandatory['value'] == true"
                      ><badge rounded type="success">{{
                        mandatory["label"]
                      }}</badge></span
                    ><span v-else
                      ><badge rounded type="danger">{{
                        mandatory["label"]
                      }}</badge></span
                    >
                  </span>
                </template>
              </el-table-column>
              <el-table-column min-width="75px" label="Seleksi">
                <template v-slot:default="props">
                  <span v-if="props.row.status == 'Active'">
                    <span v-if="props.row.already_selection == 'yes'">
                      <el-tooltip placement="top" content="Click for update">
                        <base-switch
                          v-model="already_selection_yes"
                          @click="changeAlreadySelection(props.row)"
                        ></base-switch>
                      </el-tooltip>
                    </span>
                    <span v-if="props.row.already_selection == 'no'">
                      <el-tooltip placement="top" content="Click for update">
                        <base-switch
                          v-model="already_selection_no"
                          @click="changeAlreadySelection(props.row)"
                          disabled="true"
                        ></base-switch>
                      </el-tooltip>
                    </span>
                    <span v-if="props.row.already_selection == null">
                      <small class="text-muted"><i>Tidak ada</i></small>
                    </span>
                  </span>
                  <span
                    v-else-if="
                      props.row.status == 'Passed' ||
                      props.row.status == 'Active Student' ||
                      props.row.status == 'Conditional Active Student' ||
                      props.row.status == 'Backup Active Student'
                    "
                  >
                    <i
                      class="fa fa-solid fa-clipboard-check fa-2x text-success"
                    ></i>
                  </span>
                  <span v-else-if="props.row.status == 'Fail'">
                    <el-tooltip placement="top" content="Gagal Seleksi">
                      <small class="text-danger"
                        ><i class="fa fa-ban fa-2x"></i
                      ></small>
                    </el-tooltip>
                  </span>
                  <span v-else>
                    <el-tooltip placement="top" content="Belum Seleksi">
                      <small class="text-muted"
                        ><i class="fa fa-ban fa-2x"></i
                      ></small>
                    </el-tooltip>
                  </span>
                </template>
              </el-table-column>
              <el-table-column min-width="180px" align="right" label="Actions">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div class="btn-group" role="group">
                      <base-button
                        @click="handleActivated(props.$index, props.row)"
                        type="primary"
                        size="sm"
                        v-if="props.row.status == 'Registered'"
                      >
                        <i class="fa fa-check-square"></i> Aktifkan
                      </base-button>
                      <base-button
                        @click="handlePassed(props.$index, props.row)"
                        type="success"
                        size="sm"
                        v-if="props.row.status == 'Active'"
                      >
                        <i class="fa fa-check-square"></i> Update
                      </base-button>
                      <base-button
                        @click="handleRegActiveStudent(props.$index, props.row)"
                        type="info"
                        size="sm"
                        v-if="
                          props.row.status == 'Passed' ||
                          props.row.status == 'Active Student' ||
                          props.row.status == 'Conditional Active Student' ||
                          props.row.status == 'Backup Active Student' ||
                          props.row.status == 'Fail'
                        "
                      >
                        <i class="fa fa-check-square"></i> Update
                      </base-button>
                      <base-button
                        class="remove btn-link"
                        type="default"
                        size="sm"
                        @click="handleShowProfile(props.$index, props.row)"
                        icon
                      >
                        <i class="text-white fa fa-search-plus"></i>
                      </base-button>
                      <base-button
                        @click="handleDelete(props.$index, props.row)"
                        class="remove btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="text-white fa fa-trash-alt"></i>
                      </base-button>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pl-4">
              <small
                ><i class="text-danger">{{ displayError }}</i></small
              >
            </div>
          </div>
          <template v-slot:footer>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>

            <modal v-model:show="modals.classic3">
              <Form @submit.prevent="onDeleteSubmit">
                <input type="hidden" v-model="form3.id" />
                <h6 class="modal-title">Modal Form Delete</h6>
                <hr />
                <div>
                  <div class="text-sm text-muted text-center">
                    Anda ingin menghapus data Siswa
                    <h3>{{ deleteLabelRow }}</h3>
                  </div>
                  <div class="form-group">
                    <h3 class="text-primary">#Opsi Mode Hapus Data</h3>
                    <div class="col-md-12">
                      <el-select
                        placeholder="Pilih Mode Hapus"
                        v-model="form3.delete_mode"
                        filterable
                      >
                        <el-option
                          v-for="select2 in deleteModeSelect"
                          class="select-danger"
                          :value="select2.value"
                          :label="select2.label"
                          :key="select2.label"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <br />
                    <div class="form-group">
                      <div class="col-md-12">
                        <base-checkbox
                          class="mb-3"
                          v-model="form3.delete_mode_extended"
                        >
                          Hapus Data Orang Tua juga dan yang berkaitan
                        </base-checkbox>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <base-button
                    type="danger"
                    :loading="isLoading"
                    native-type="submit"
                    >Hapus Data</base-button
                  >
                  <base-button
                    type="link"
                    class="ml-auto"
                    @click="modals.classic3 = false"
                    >Close</base-button
                  >
                </div>
              </Form>
            </modal>

            <!-- Modal Form Input -->
            <modal v-model:show="modals.classic2">
              <Form @submit.prevent="onSubmitRegRegisteredToActiveStatus">
                <div>
                  <h3 class="text-primary">#Update Status Calon Siswa</h3>
                  <div class="form-group">
                    <label class="col-md-12 col-form-label form-control-label">
                      <div
                        class="text-left"
                        v-html="reg_registered_to_active_desc"
                      ></div>
                    </label>
                  </div>
                  <hr style="margin-top: -20px" />
                  <div class="form-group" style="margin-top: -20px">
                    <label class="col-md-12 col-form-label form-control-label"
                      >Kirim Pesan WA</label
                    >
                    <div class="col-md-12">
                      <base-switch
                        v-model="form2.switches_wa_message"
                        @change="changeSwitchWAMessage2"
                      ></base-switch>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="show_wa_message2"
                    style="margin-top: -20px"
                  >
                    <label class="col-md-12 col-form-label form-control-label"
                      >Pesan WA</label
                    >
                    <div class="col-md-12">
                      <textarea
                        v-model="form2.wa_message"
                        class="form-control"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <hr />
                  <base-button
                    type="default"
                    :loading="isLoading"
                    native-type="submit"
                    >Yes, aktifkan sekarang!</base-button
                  >
                  <base-button
                    type="link"
                    class="ml-auto"
                    @click="modals.classic2 = false"
                    >Close</base-button
                  >
                </div>
              </Form>
            </modal>

            <modal v-model:show="modals.classic">
              <Form @submit.prevent="onSubmitRegSelectionStatusStudent">
                <div>
                  <h3 class="text-primary">#Update Status Calon Siswa</h3>
                  <div class="form-group">
                    <label class="col-md-12 col-form-label form-control-label">
                      {{ choose.reg_noreg }} / {{ choose.reg_fullname }} /
                      {{ choose.reg_unit_name }}
                    </label>
                  </div>
                  <hr style="margin-top: -20px" />
                  <div class="form-group" style="margin-top: -20px">
                    <label class="col-md-12 col-form-label form-control-label"
                      >Hasil Tes Seleksi</label
                    >
                    <div class="col-md-12">
                      <select
                        v-model="form.status"
                        class="form-control text-dark"
                        @change="changeWAMessagebyStatus"
                        required
                      >
                        <option
                          v-for="select in selects.regselectionstatus"
                          v-bind:key="select.value"
                          v-bind:value="select.value"
                        >
                          {{ select.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group" style="margin-top: -20px">
                    <label class="col-md-12 col-form-label form-control-label"
                      >Kirim Pesan WA</label
                    >
                    <div class="col-md-12">
                      <base-switch
                        v-model="form.switches_wa_message"
                        @change="changeSwitchWAMessage"
                      ></base-switch>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="show_wa_message"
                    style="margin-top: -20px"
                  >
                    <label class="col-md-12 col-form-label form-control-label"
                      >Pesan WA</label
                    >
                    <div class="col-md-12">
                      <textarea
                        v-model="form.wa_message"
                        class="form-control"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <hr />
                  <base-button
                    type="default"
                    :loading="isLoading"
                    native-type="submit"
                    >Submit Data</base-button
                  >
                  <base-button
                    type="link"
                    class="ml-auto"
                    @click="modals.classic = false"
                    >Close</base-button
                  >
                </div>
              </Form>
            </modal>
            <!-- Modal Form Input -->
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Auth from "@/components/Auth/Auth";
import axios from "axios";
import Modal from "@/components/Modal";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElTooltip,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
import RegProfileInformation from "@/components/Datas/RegProfileInformation";
import swal from "sweetalert2";

export default {
  name: "RegRegisteredTables",
  components: {
    Auth,
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    RegProfileInformation,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
        classic3: false,
      },
      urldelete: "/api/reg/delete",
      isLoading: false,
      already_selection_yes: true,
      already_selection_no: false,
      showtable: true,
      showprofile: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["display_noreg_fullname"],
      tableColumns: [
        {
          type: "selection",
        },
      ],
      regs: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      loadingTable: false,
      displayError: "",
      choose: {
        reg_row: "",
        reg_id: "",
        reg_noreg: "",
        reg_fullname: "",
        reg_unit: "",
        settingapp: [],
      },
      selects: {
        id_ta: "",
        id_unit: "",
        id_ppdb: "",
        ppdbselected: [],
        ppdbselected_label: "",
        status: 0,
        import: 'alldata',
        tas: [],
        units: [],
        ppdbs: [],
        regstatus: [
          { value: 0, label: "-- All Status --" },
          { value: 1, label: "Terdaftar" },
          { value: 2, label: "Aktif" },
          //{ value: 3, label: "Passed" },
          { value: 4, label: "Lulus Aktif" },
          { value: 5, label: "Lulus Bersyarat" },
          { value: 6, label: "Lulus Cadangan" },
          { value: 7, label: "Tidak Lulus" },
        ],
        regselectionstatus: [
          { value: 4, label: "Lulus Aktif" },
          { value: 5, label: "Lulus Bersyarat" },
          { value: 6, label: "Lulus Cadangan" },
          { value: 7, label: "Tidak Lulus" },
        ],
        regimport: [
          { value: "alldata", label: "-- All Data --" },
          { value: "notimport", label: "Show Data Not Import" },
          { value: "import", label: "Show Data Import" },
        ],
      },
      form: {
        status: "",
        wa_message: "",
        switches_wa_message: true,
      },
      form2: {
        status: "",
        wa_message: "",
        switches_wa_message: true,
      },
      form3: {
        id: "",
        delete_mode: 1,
        delete_mode_extended: false,
      },
      show_wa_message: true,
      show_wa_message2: true,
      profile: [],
      eduinfo: [],
      switches: false,
      switches2: false,
      reg_registered_to_active_desc: "",

      deleteModeSelect: [
        {
          value: 1,
          label: "Hapus Data Siswa beserta data yang berkaitan lainnya",
        },
      ],
      deleteLabelRow: "",
    };
  },
  created() {
    this.TASelect();
    this.UnitSelect();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      return loading;
    },
    swalBootstrap() {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });

      return swalWithBootstrapButtons3;
    },
    checkParam() {
      if (
        this.$route.params.id_ta !== undefined &&
        this.$route.params.id_unit !== undefined &&
        this.$route.params.id_ppdb !== undefined
      ) {
        this.selects.id_ta = parseInt(this.$route.params.id_ta);
        this.selects.id_unit = parseInt(this.$route.params.id_unit);
        this.selects.id_ppdb = parseInt(this.$route.params.id_ppdb);

        this.PPDBSelect(this.selects.id_ta, this.selects.id_unit);
      }
    },
    async TASelect() {
      try {
        const response = await axios.get("/api/ta/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.tas = response.data;

        let taslength = response.data.length;
        if (taslength > 0) {
          let selectedTA = taslength - 1;
          this.selects.id_ta = this.selects.tas[selectedTA].value;

          this.checkParam();

          this.getRegsRegistered();
        }
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    async UnitSelect() {
      try {
        const response = await axios.get("/api/unit/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.units = response.data;
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    handleSelectPPDB() {
      let id_ta = "";
      let id_unit = 0;
      this.selects.id_ppdb = "";
      if (this.selects.id_ta != "") {
        id_ta = this.selects.id_ta;
        if (this.selects.id_unit != "") {
          id_unit = this.selects.id_unit;
        }
        this.PPDBSelect(id_ta, id_unit);
      }
    },
    async PPDBSelect(id_ta, id_unit) {
      try {
        const response = await axios.get(
          "/api/ppdb/select/" + id_ta + "/" + id_unit,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.selects.ppdbs = response.data;
        //console.log(this.selects.ppdbs)
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    handleFormFilter() {
      this.getRegsRegistered();
    },
    async getRegsRegistered() {
      try {
        let id_ta = this.selects.id_ta;
        let id_unit = 0;
        let id_ppdb = 0;
        let regstatus = this.selects.status;
        let regimport = this.selects.import;
        if (this.selects.id_unit != "") {
          id_unit = this.selects.id_unit;
        }
        if (this.selects.id_ppdb != "") {
          id_ppdb = this.selects.id_ppdb;
        }
        let paramurl = id_ta + "/" + id_unit + "/" + id_ppdb + "/" + regstatus + "/" + regimport;
        let url = "/api/reg/registered/" + paramurl;

        this.loadingTable = true;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.regs = response.data;
        this.tableData = this.regs;
        console.log(this.regs);

        this.loadingTable = false;
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
        this.loadingTable = false;
      }
    },
    selectedPPDB(option) {
      if (option === undefined) {
        this.selects.ppdbselected = [];
        this.selects.ppdbselected_label = "";
      } else {
        this.selects.ppdbselected = option;
        this.selects.ppdbselected_label = option.label;
      }
    },
    handleActivated(index, row) {
      //alert(row.id);
      this.modals.classic2 = true;
      this.form2.switches_wa_message = true;
      this.show_wa_message2 = true;
      this.choose.reg_row = row;
      this.choose.reg_id = row.id;

      this.reg_registered_to_active_desc =
        row.fullname +
        " (" +
        row.unitlevels_name +
        ")<br />Syarat: Sudah lunas Tagihan<br /><b>(" +
        row.paidlist +
        ")</b>";

      this.form2.wa_message =
        "Assalamu'alaikum warohmatullohi wa barakatuh,\n\nAlhamdulillah ananda *" +
        row.fullname +
        "* telah *Aktif* sebagai calon siswa *" +
        row.unit_name +
        "* di Sekolah An-Nisaa Izada,\n\n" +
        "Untuk selanjutnya silakan login dan melengkapi data pendaftaran melalui:\n" +
        row.settingapp["psb_link"] +
        "\n\n" +
        "Terima kasih.\nPanita Penerimaan Siswa Baru " +
        row.settingapp["name"] +
        ".";

      /*
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Aktifkan Calon Siswa ini?",
          html:
            row.fullname +
            " (" +
            row.unitlevels_name +
            ")<br />Syarat: Sudah lunas Tagihan<br /><b>(" +
            row.paidlist +
            ")</b>",
          showCancelButton: true,
          confirmButtonText: "Yes, aktifkan sekarang!",
          showLoaderOnConfirm: true,
          preConfirm: (activated) => {
            try {
              const response = axios.get("/api/reg_activate/" + row.id, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              });
              return response.data;
            } catch (error) {
              console.log(error);
              this.errorActivate = error;
              swalWithBootstrapButtons3.showValidationMessage(
                `Request failed: ${error}`
              );
            }
          },
          allowOutsideClick: () => !swalWithBootstrapButtons3.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteRow(row);
            //this.activatedRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Diaktifkan!",
              text: `Anda telah mengaktifkan ${row.fullname}`,
            });
          }
        });
      */
    },
    handlePassed(index, row) {
      this.modals.classic = true;

      this.form.switches_wa_message = true;
      this.show_wa_message = true;
      this.form.status = "";
      this.form.wa_message = "";

      this.choose.reg_row = row;
      this.choose.reg_id = row.id;
      this.choose.reg_noreg = row.noreg;
      this.choose.reg_fullname = row.fullname;
      this.choose.reg_unit_name = row.unit_name;
      this.choose.settingapp = row.settingapp;

      /*
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Apakah Calon Siswa ini Lulus Test?",
          html:
            row.fullname +
            " (" +
            row.unitlevels_name +
            ")<br />Syarat: Sudah melengkapi<br /><b>(Data Kelngkapan Calon Siswa)</b> dan<br />lalu update kelulusan Seleksi <b>(" +
            row.selection +
            ")</b><br /><br />" +
            "<b>Status Kelulusan:</b> " +
            "<select class='form-control' id='swal-statusSelectionValue'>" +
            "<option value='4'>Lulus Aktif</option>" +
            "<option value='5'>Lulus Aktif Bersyarat</option>" +
            "<option value='6'>Lulus Aktif Cadangan</option>" +
            "<option value='7'>Gagal</option>" +
            "</select><br /><br />",
          focusConfirm: false,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return [document.getElementById("swal-statusSelectionValue").value];
          },
          showCancelButton: true,
          confirmButtonText: "Yes, update!",
          allowOutsideClick: () => !swalWithBootstrapButtons3.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            let statusSelection = result.value[0];

            this.RegSelectionStatusStudent(row, statusSelection);
          }
        });
      */
    },

    async onSubmitRegRegisteredToActiveStatus() {
      let row = this.choose.reg_row;
      let id_reg = this.choose.reg_id;

      let loading = this.loadingService();
      let swalBootstrap = this.swalBootstrap();

      try {
        const response = await axios({
          method: "post",
          url: "/api/reg/activate/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            switch_wa_message: this.form2.switches_wa_message,
            wa_message: this.form2.wa_message,
          },
        });
        if (response.data.status == "error") {
          swalBootstrap.fire({
            title: "Warning!",
            text: response.data.message,
            type: "warning",
          });
        } else {
          this.deleteRow(row);
          swalBootstrap.fire({
            title: "Good job!",
            text: "Anda telah mengupdate " + this.choose.reg_fullname,
          });

          this.modals.classic2 = false;
        }
        loading.close();
      } catch (error) {
        console.log(error);
        loading.close();
        //this.modals.classic = false;
        swalBootstrap.fire({
          title: "Error!",
          text: error,
          type: "error",
        });
      }
    },
    async onSubmitRegSelectionStatusStudent() {
      let row = this.choose.reg_row;
      let id_reg = this.choose.reg_id;
      let statusSelection = this.form.status;

      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      try {
        const response = await axios({
          method: "post",
          url: "/api/reg_passed/" + id_reg + "/" + statusSelection,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            switch_wa_message: this.form.switches_wa_message,
            wa_message: this.form.wa_message,
          },
        });

        this.passed = response.data;
        if (response.data.status == "error") {
          swalWithBootstrapButtons3.fire({
            title: "Warning!",
            text: response.data.message,
            type: "warning",
          });
        } else {
          this.deleteRow(row);
          swalWithBootstrapButtons3.fire({
            title: "Good job!",
            text: "Anda telah mengupdate " + this.choose.reg_fullname,
          });

          this.modals.classic = false;
        }
        loading.close();
      } catch (error) {
        this.errorPassed = error;
        console.log(error);
        loading.close();
        this.modals.classic = false;
        swalWithBootstrapButtons3.fire({
          title: "Error!",
          text: error,
          type: "error",
        });
      }
    },
    handleRegActiveStudent(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      const { value: formValues } = swalWithBootstrapButtons3
        .fire({
          title: "Update Status Siswa Pembayaran Uang Pangkal?",
          html:
            row.fullname +
            " (" +
            row.unitlevels_name +
            ")<br />Syarat: <b>Sudah lunas/cicilan melakukan pembayaran Pendaftaran Ulang</b><br /><br />" +
            "<div class='text-left'>" +
            "<b>Uang Pangkal:</b> <select class='form-control' id='swal-paynoteValue'><option value='Unpaid'>Belum Bayar (Unpaid)</option><option value='Installment'>Cicilan (Installment)</option><option value='Fullpayment'>Lunas (Fullpayment)</option></select><br />" +
            "<b>Promo Label:</b> " +
            "<select class='form-control' id='swal-promoLabelValue'>" +
            "<option value='Early Bird'>Early Bird</option>" +
            "<option value='Sibling'>Sibling</option>" +
            "<option value='Keeping the star'>Keeping the star</option>" +
            "<option value='Beasiswa Prestasi'>Beasiswa Prestasi</option>" +
            "</select><br />" +
            "</div>",
          focusConfirm: false,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return [
              document.getElementById("swal-paynoteValue").value,
              document.getElementById("swal-promoLabelValue").value,
            ];
          },
          showCancelButton: true,
          confirmButtonText: "Yes, update status!",
          allowOutsideClick: () => !swalWithBootstrapButtons3.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            let paynote = result.value[0];
            let promoLabel = result.value[1];
            //alert(result.value[0]+' '+result.value[1]);
            //alert(JSON.stringify(result.value))
            //this.RegActiveStudent(row,paynote,statusPassed);
            this.RegActiveStudent(row, paynote, promoLabel);
          }
        });
    },
    handleDelete(index, row) {
      this.modals.classic3 = true;
      // display label
      this.deleteLabelRow = row.fullname;
      this.form3.id = row.id;
    },
    async onDeleteSubmit() {
      let loading = this.loadingService();
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: this.urldelete + "/" + this.form3.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form3,
        });
        let data = response.data;
        //console.log(data);

        // close loader
        loading.close();
        this.isLoading = false;
        this.modals.classic3 = false;

        if (data.warning_message != "") {
          swal.fire("Warning!", data.warning_message, "warning");
        } else {
          swal.fire("Success!", data.message, "success");
        }

        // load table
        this.getRegsRegistered();
      } catch (error) {
        console.log(error);
        loading.close();
        this.isLoading = false;
        swal.fire("Error!", error.message, "error");
      }
    },
    handleDeleteOld(index, row) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons3
        .fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(row);
            swalWithBootstrapButtons3.fire({
              title: "Deleted!",
              text: `You deleted ${row.name}`,
            });
          }
        });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    /*
    async RegActiveStudent(row,paynote,statusPassed) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      try {
        const response = await axios.get(
          "/api/reg_candidate/" +
            row.id +
            "/" +
            paynote +
            "/" +
            statusPassed,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if(response.data.status == 'error') {
          swalWithBootstrapButtons3.fire({
            title: "Warning!",
            text: response.data.message,
            type: 'warning',
          });
        }else{
          //this.passed = response.data;
          //console.log(response.data);
          this.deleteRow(row);
          swalWithBootstrapButtons3.fire({
            title: "Good job!",
            text: `Anda telah mengupdate ${row.fullname}`,
          });
        }
      } catch (error) {
        this.errorPassed = error;
        console.log(error);
      }
    },
    */
    async RegActiveStudent(row, paynote, promoLabel) {
      const swalWithBootstrapButtons3 = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      try {
        const response = await axios({
          method: "post",
          url: "/api/reg_candidate/" + row.id + "/" + paynote,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            promo_label: promoLabel,
          },
        });

        if (response.data.status == "error") {
          swalWithBootstrapButtons3.fire({
            title: "Warning!",
            text: response.data.message,
            type: "warning",
          });
        } else {
          //this.passed = response.data;
          //console.log(response.data);
          //this.deleteRow(row);
          swalWithBootstrapButtons3.fire({
            title: "Good job!",
            text: `Anda telah mengupdate ${row.fullname}`,
          });
          this.getRegsRegistered();
        }
      } catch (error) {
        this.errorPassed = error;
        console.log(error);
      }
    },
    async getEduInfo(row) {
      this.eduinfo = [];
      try {
        const response = await axios.get("/api/eduinfo/" + row.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.eduinfo = response.data;
      } catch (error) {
        this.errorPassed = error;
        console.log(error);
      }
    },
    handleShowProfile(index, row) {
      //this.getEduInfo(row);
      this.showtable = false;
      this.showprofile = true;

      this.profile = row;
      console.log(this.profile);
    },
    handleShowTable() {
      this.showtable = true;
      this.showprofile = false;
    },
    async changeAlreadySelection(row) {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let id_reg = row.id;

      try {
        const response = await axios({
          method: "get",
          url: "/api/reg/get_already_selection/" + id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //loading.close();

        let get_already_selection = response.data.already_selection;
        let value_already_selection = "";
        if (get_already_selection == "yes") {
          value_already_selection = "no";
        } else if (get_already_selection == "no") {
          value_already_selection = "yes";
        }

        if (value_already_selection != "") {
          this.updateAlreadySelection(id_reg, value_already_selection, loading);
        }
      } catch (error) {
        this.errorPassed = error;
        console.log(error);
        loading.close();
      }
    },
    async updateAlreadySelection(id_reg, value_already_selection, loading) {
      try {
        const response = await axios({
          method: "post",
          url: "/api/reg/change_already_selection/" + id_reg,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            already_selection: value_already_selection,
          },
        });
        loading.close();
        if (response.data.status == "error") {
          swal.fire("Warning!", response.data.message, "warning");
        }
      } catch (error) {
        this.errorPassed = error;
        console.log(error);
        loading.close();
      }
    },
    changeSwitchWAMessage() {
      // get switch
      let switch_wa_message = this.form.switches_wa_message;
      if (switch_wa_message == true) {
        this.form.switches_wa_message = false;
        this.show_wa_message = false;
      } else {
        this.form.switches_wa_message = true;
        this.show_wa_message = true;
      }
    },
    changeSwitchWAMessage2() {
      // get switch
      let switch_wa_message2 = this.form2.switches_wa_message;
      if (switch_wa_message2 == true) {
        this.form2.switches_wa_message = false;
        this.show_wa_message2 = false;
      } else {
        this.form2.switches_wa_message = true;
        this.show_wa_message2 = true;
      }
    },
    changeWAMessagebyStatus() {
      let status = this.form.status;
      switch (status) {
        case 4:
          this.form.wa_message =
            "Assalamu'alaikum warohmatullohi wa barakatuh, Ananda *" +
            this.choose.reg_fullname +
            "*,\n\nTerima kasih sudah mengikuti proses seleksi calon siswa " +
            this.choose.reg_unit_name +
            " di " +
            this.choose.settingapp["name"] +
            ",\n\nUntuk selanjutnya silakan login untuk melihat status kelulusan, di:\n" +
            this.choose.settingapp["psb_link"] +
            "\n\nTerima kasih.\nPanita Penerimaan Siswa Baru " +
            this.choose.settingapp["name"] +
            ".";
          break;
        case 5:
          this.form.wa_message =
            "Assalamu'alaikum warohmatullohi wa barakatuh, Ananda *" +
            this.choose.reg_fullname +
            "*,\n\nTerima kasih sudah mengikuti proses seleksi calon siswa " +
            this.choose.reg_unit_name +
            " di " +
            this.choose.settingapp["name"] +
            ",\n\nUntuk selanjutnya silakan login untuk melihat status kelulusan, di:\n" +
            this.choose.settingapp["psb_link"] +
            "\n\nTerima kasih.\nPanita Penerimaan Siswa Baru " +
            this.choose.settingapp["name"] +
            ".";
          break;
        case 6:
          this.form.wa_message =
            "Assalamu'alaikum warohmatullohi wa barakatuh, Ananda *" +
            this.choose.reg_fullname +
            "*,\n\nTerima kasih sudah mengikuti proses seleksi calon siswa " +
            this.choose.reg_unit_name +
            " di " +
            this.choose.settingapp["name"] +
            ",\n\nUntuk selanjutnya silakan login untuk melihat status kelulusan, di:\n" +
            this.choose.settingapp["psb_link"] +
            "\n\nTerima kasih.\nPanita Penerimaan Siswa Baru " +
            this.choose.settingapp["name"] +
            ".";
          break;
        case 7:
          this.form.wa_message =
            "Assalamu'alaikum warohmatullohi wa barakatuh, Ananda *" +
            this.choose.reg_fullname +
            "*,\n\nTerima kasih sudah mengikuti proses seleksi calon siswa " +
            this.choose.reg_unit_name +
            " di " +
            this.choose.settingapp["name"] +
            ",\n\nUntuk selanjutnya silakan login untuk melihat status kelulusan, di:\n" +
            this.choose.settingapp["psb_link"] +
            "\n\nTerima kasih.\nPanita Penerimaan Siswa Baru " +
            this.choose.settingapp["name"] +
            ".";
          break;
      }
    },
    async exportPDF() {
      let loading = this.loadingService();
      let id_ta = this.selects.id_ta;
      let id_unit = 0;
      let id_ppdb = 0;
      let status = this.selects.status;
      if (this.selects.id_unit != "") {
        id_unit = this.selects.id_unit;
      }
      if (this.selects.id_ppdb != "") {
        id_ppdb = this.selects.id_ppdb;
      }
      let random = Math.random().toString(16).slice(2);
      let paramurl =
        id_ta + "/" + id_unit + "/" + id_ppdb + "/" + status + "/" + random;

      let url = "/api/reg/exportpdfregistered/" + paramurl;

      try {
        const response = await axios({
          method: "get",
          url: url,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        if (response.data.status == "error") {
          swal.fire("Warning!", response.data.message, "warning");
          loading.close();
        } else {
          loading.close();
          //Swal.fire("Good job!", response.data.message, "success");
          let urltofile = response.data.urltofile;
          //window.location.href = urltofile;
          window.open(urltofile);
          //loading.close();
        }
      } catch (error) {
        console.log(error);
        swal.fire("Error!", error.message, "error");
        loading.close();
      }
    },
    async exportEXCEL() {
      let loading = this.loadingService();
      let id_ta = this.selects.id_ta;
      let id_unit = 0;
      let id_ppdb = 0;
      let status = this.selects.status;
      if (this.selects.id_unit != "") {
        id_unit = this.selects.id_unit;
      }
      if (this.selects.id_ppdb != "") {
        id_ppdb = this.selects.id_ppdb;
      }
      // let random = Math.random().toString(16).slice(2);
      let paramurl =
        "?id_unit=" + id_unit + "&id_ppdb=" + id_ppdb + "&status=" + status + "&id_ta=" + id_ta;

      let url = "/api/export_excel/" + paramurl;
      window.open(axios.defaults.baseURL + url);

      // try {
      //   const response = await axios({
      //     method: "get",
      //     url: url,
      //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      //   });
      //   if (response.data.status == "error") {
      //     swal.fire("Warning!", response.data.message, "warning");
      //     loading.close();
      //   } else {
      //     swal.fire("Good job!", response.data.message, "success");
      //     let urltofile = response.data.urltofile;
      //     window.location.href = urltofile;
      //     loading.close();
      //   }
      // } catch (error) {
      //   console.log(error);
      //   swal.fire("Error!", error.message, "error");
      //   loading.close();
      // }
      loading.close();
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
