<template>
  <div>
    <div
      class="col-12 justify-content-center justify-content-sm-between flex-wrap"
    >
      <Form @submit.prevent="onSubmit" :validation-schema="schema">
        <GenInput
          :forminit="form"
          :fieldspecial="fieldspecial"
          :fieldtitle="fieldtitle"
        />
        <div class="form-group row">
          <label class="col-md-2 col-form-label form-control-label"
            >Panduan Pendaftaran</label
          >
          <div class="col-md-10">
            <editor
              v-model="form.ppdb_rule"
              api-key="bjc8l2f085drd2l1t7lstjorf7re178s43dxj7ukpyid5a71"
              :init="{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image table charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
                            table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | \
           bullist numlist outdent indent | removeformat | help',
              }"
            />
          </div>
        </div>
        <br />
        <div class="text-right">
          <base-button type="default" :loading="isLoading" native-type="submit"
            >Simpan Data</base-button
          >
        </div>
      </Form>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <GenUpload
            label="Upload File"
            formatinfo="Upload file dokumen berformat JPG/PNG"
            sizeinfo="Maksimum ukuran 2MB."
            type="ppdb_rule_image"
            accept="image/jpeg, image/png, image/gif"
            url_upload="api/settingapps/ppdb_rule_image/store"
            url_read="api/settingapps/ppdb_rule_image/read"
            pathfolder="/settingapps"
          ></GenUpload>
        </div>
        <div class="col-md-6"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import GenInput from "@/components/Generate/GenInput";
import GenUpload from "@/components/Generate/GenUpload.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "SettingAppForm.vue",
  components: {
    GenInput,
    GenUpload,
    Editor,
  },
  data() {
    return {
      datas: [],
      formGenerators: [],
      form: {
        id: "",
        ppdb_rule: "",
      },
      fieldspecial: {
        id: {
          input_type: "hidden",
          label: "",
        },
        ppdb_rule: {
          input_type: "exclude",
          label: "",
        },
      },
      fieldtitle: {
        ppdb_rule: {
          title: "PPDB Rule",
        },
      },
      isLoading: false,
    };
  },
  created() {
    this.getPpdbRule();
  },
  methods: {
    async getPpdbRule() {
      try {
        const response = await axios.get("/api/settingapps/ppdb_rule/read", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data;
        for (let x in this.datas) {
          this.form[x] = this.datas[x];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: "/api/settingapps/ppdb_rule/store",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });

        Swal.fire("Good job!", "Data berhasil disimpan!", "success");
        console.log(response.data);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>