<template>
  <div class="content">
    <Auth />
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Setting Apps</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Setting Apps</h3>
            <p class="text-sm mb-0">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </template>
          <div>
            <div
              class="
                col-12
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
            
              <!-- Start Tabs Content -->
              <ul class="nav nav-pills nav-fill" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="data01-tab"
                    data-toggle="tab"
                    href="#data01"
                    role="tab"
                    aria-controls="data01"
                    aria-selected="true"
                    >Theme App</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="data02-tab"
                    data-toggle="tab"
                    href="#data02"
                    role="tab"
                    aria-controls="data02"
                    >Daftar Nama Sekolah</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="data03-tab"
                    data-toggle="tab"
                    href="#data03"
                    role="tab"
                    aria-controls="data03"
                    >Daftar Info Registrasi</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="data04-tab"
                    data-toggle="tab"
                    href="#data04"
                    role="tab"
                    aria-controls="data04"
                    >Daftar Relasi Keluarga</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="data05-tab"
                    data-toggle="tab"
                    href="#data05"
                    role="tab"
                    aria-controls="data05"
                    >Daftar Jenis Pekerjaan</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="data06-tab"
                    data-toggle="tab"
                    href="#data06"
                    role="tab"
                    aria-controls="data06"
                    >Daftar Rumah Sakit</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="data07-tab"
                    data-toggle="tab"
                    href="#data07"
                    role="tab"
                    aria-controls="data07"
                    >Daftar Moda Transportasi</a
                  >
                </li>
              </ul>
              <br />
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="data01"
                  role="tabpanel"
                  aria-labelledby="data01-tab"
                >
                  <div class="card">
                    <div class="card-body">
                      <br />
                      <SettingAppForm />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="data02"
                  role="tabpanel"
                  aria-labelledby="data02-tab"
                >
                  <div class="card">
                    <div class="card-body">
                      <br />
                      <SchesTables />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="data03"
                  role="tabpanel"
                  aria-labelledby="data03-tab"
                >
                  <div class="card">
                    <div class="card-body">
                        <InfregsTables />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="data04"
                  role="tabpanel"
                  aria-labelledby="data04-tab"
                >
                  <div class="card">
                    <div class="card-body">
                        <RelationsTables />
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="data05"
                  role="tabpanel"
                  aria-labelledby="data05-tab"
                >
                  <div class="card">
                    <div class="card-body">
                        <WorksTables />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="data06"
                  role="tabpanel"
                  aria-labelledby="data06-tab"
                >
                  <div class="card">
                    <div class="card-body">
                        <HospitalsTables />
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="data07"
                  role="tabpanel"
                  aria-labelledby="data07-tab"
                >
                  <div class="card">
                    <div class="card-body">
                        <ModaTransportationsTables />
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Tabs Content -->
            </div>
          </div>

          <template v-slot:footer>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            ></div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/Auth/Auth";
import SettingAppForm from "@/components/Datas/SettingAppForm";
import SchesTables from "@/components/Datas/SchesTables";
import InfregsTables from "@/components/Datas/InfregsTables";
import RelationsTables from "@/components/Datas/RelationsTables";
import WorksTables from "@/components/Datas/WorksTables";
import HospitalsTables from "@/components/Datas/HospitalsTables";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import ModaTransportationsTables from "@/components/Datas/ModaTransportationsTables";

export default {
  name: "SettingApps",
  components: {
    Auth,
    RouteBreadCrumb,
    SettingAppForm,
    SchesTables,
    InfregsTables,
    RelationsTables,
    WorksTables,
    HospitalsTables,
    ModaTransportationsTables
  },
};
</script>

<style>
</style>