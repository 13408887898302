<template>
  <div class="container text-center mt-5 mb-5 pt-5 pb-5 text-muted">
    <i>please wait for remove auth and redirection...</i><br />
  </div>
</template>

<script>
export default {
  name: "Logout",
  date() {
    return {
      token: "",
    };
  },
  created() {
    this.removeAuth();
  },
  methods: {
    removeAuth() {
      localStorage.setItem("token", "");
      setTimeout(() => {
        this.$router.push({ path: "/login" });
      }, 1000);
    },
  },
};
</script>

<style>
</style>