<template>
  <div v-if="formImportUpload">
    <div class="row mt-5 mb-5">
      <div class="col-md-2 col-sm-12"></div>
      <div class="col-md-8 col-sm-12">
        <card class="text-center p-3">
          <div class="text-center mb-3">
            <h3>Lengkapi data (Tahun Ajaran/Unit/PPDB)</h3>
          </div>
          <div
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <table>
              <tr>
                <td>
                  <el-select
                    placeholder="Pilih Tahun Ajar"
                    v-model="selects.id_ta"
                    @change="handleSelectPPDB"
                    filterable
                  >
                    <el-option
                      v-for="option in selects.tas"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                  </el-select>
                  &nbsp;
                  <el-select
                    placeholder="Pilih Unit"
                    v-model="selects.id_unit"
                    @change="handleSelectPPDB"
                    filterable
                  >
                    <el-option value="0" label="-- Pilih Unit --"
                      >-- Pilih Unit --</el-option
                    >
                    <el-option
                      v-for="option2 in selects.units"
                      class="select-danger"
                      :value="option2.value"
                      :label="option2.label"
                      :key="option2.label"
                    >
                    </el-option>
                  </el-select>
                  &nbsp;
                  <el-select
                    placeholder="Pilih PPDB"
                    v-model="selects.id_ppdb"
                    filterable
                  >
                    <el-option
                      v-for="option3 in selects.ppdbs"
                      class="select-danger"
                      :value="option3.value"
                      :label="option3.label"
                      :key="option3.label"
                      @click="selectedPPDB(option3)"
                    >
                    </el-option>
                  </el-select>
                </td>
              </tr>
            </table>
          </div>
          <div class="text-center text-sm text-muted mt-3">
            {{ PPDBSelectLabel }}
          </div>
          <hr />
          <blockquote class="blockquote mb-0" v-if="dropzoneUpload">
            <dropzone-file-upload
              v-model="fileUpload"
              @change="onFileSelected"
              :accept="accept"
            ></dropzone-file-upload>
            <footer class="blockquote-footer">
              <small>
                File upload import bertipe.csv |
                <cite title="Download contoh format file import"
                  ><a href="#">Contoh format file</a></cite
                >
              </small>
            </footer>
          </blockquote>
        </card>
      </div>
      <div class="col-md-2 col-sm-12"></div>
    </div>
  </div>

  <div v-if="tableImportPreview">
    <div>
      <div
        class="
          col-12
          d-flex
          justify-content-center justify-content-sm-between
          flex-wrap
        "
      >
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div>
          <div class="btn-group" role="group">
            <Form @submit.prevent="openFormUpload">
              <base-button type="default" native-type="submit"
                >Re-Upload</base-button
              >
            </Form>
          </div>
          &nbsp;
          <el-input
            type="search"
            class="mb-3"
            clearable
            prefix-icon="el-icon-search"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          >
          </el-input>
        </div>
      </div>

      <el-table
        :data="queriedData"
        row-key="id"
        header-row-class-name="thead-light"
        @selection-change="selectionChange"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
        >
        </el-table-column>
        <el-table-column label="Profile Siswa">
          <template v-slot:default="props">
            <span v-html="props.row.reg_profile"></span>
          </template>
        </el-table-column>
        <el-table-column label="Akademik">
          <template v-slot:default="props">
            <span v-html="props.row.reg_academic"></span>
          </template>
        </el-table-column>
        <el-table-column label="Orang Tua Wali">
          <template v-slot:default="props">
            <span v-html="props.row.column_parentguard"></span>
          </template>
        </el-table-column>
        <el-table-column label="Status Import">
          <template v-slot:default="props">
            <span v-html="props.row.reg_status_import"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <br />
    <div
      class="
        col-12
        d-flex
        justify-content-center justify-content-sm-between
        flex-wrap
      "
    >
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>

      <div style="max-width:300px;">
        <div class="text-xs mb-2 text-center" v-html="scanningResult"></div>
        <div v-if="showButtonReadyToImport" class="text-center mb-3">
          <Form @submit.prevent="actionImportData">
            <base-button type="primary" native-type="submit"
              ><i class="ni ni-cloud-upload-96"></i> Import Data</base-button
            >
          </Form>
        </div>
      </div>
      
      
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>
    <div>
      <small
        ><small v-if="import_action_error_message">
          <hr />
          <i>
            Catatan log error:
            <ul class="text-danger">
              <li
                v-for="listerror in import_action_error_message"
                v-bind:key="listerror"
              >
                {{ listerror }}
              </li>
            </ul>
          </i>
        </small></small
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import GenInput from "@/components/Generate/GenInput";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "ImportAction",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    GenInput,
    DropzoneFileUpload,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      modals: {
        classic: false,
      },
      urlimportupload: "/api/reg/import/upload",
      urlimportstore: "/api/reg/import/store",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "reg_profile",
        "reg_academic",
        "column_parentguard",
        "reg_status_import",
      ],
      tableColumns: [
        {
          type: "selection",
        },
      ],

      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      loadingTable: "",
      displayError: "",

      selects: {
        id_ta: "",
        id_unit: "",
        id_ppdb: "",
      },

      isLoading: false,

      showFileUploadForm: true,
      showButtonReadyToImport: false,
      selectedFile: null,
      loadingFile: false,
      uploadProgressFile: "",
      checkListSuccess: "",
      filename: "",
      urlFile: "",
      basePath: axios.defaults.baseURL,
      accept: ".csv",
      csv: [],
      tableImportPreview: false,
      formImportUpload: true,
      fileUpload: [],
      count: 0,
      import_action_error_message: "",

      dropzoneUpload: false,
      PPDBSelectLabel: "",
      scanningResult: "",
    };
  },
  created() {
    this.getDefaultVar();
    this.TASelect();
    this.UnitSelect();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
    },
    overlayLoading() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      return loading;
    },
    async TASelect() {
      try {
        const response = await axios.get("/api/ta/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.tas = response.data;

        let taslength = response.data.length;
        if (taslength > 0) {
          let selectedTA = taslength - 1;
          this.selects.id_ta = this.selects.tas[selectedTA].value;
        }
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    async UnitSelect() {
      try {
        const response = await axios.get("/api/unit/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.units = response.data;
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    handleSelectPPDB() {
      let id_ta = "";
      let id_unit = 0;
      this.selects.id_ppdb = "";
      if (this.selects.id_ta != "") {
        id_ta = this.selects.id_ta;
        if (this.selects.id_unit != "") {
          id_unit = this.selects.id_unit;
        }
        this.PPDBSelect(id_ta, id_unit);
      }
    },
    async PPDBSelect(id_ta, id_unit) {
      try {
        const response = await axios.get(
          "/api/ppdb/select/" + id_ta + "/" + id_unit,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.selects.ppdbs = response.data;
        //console.log(this.selects.ppdbs)
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
      }
    },
    selectedPPDB(option) {
      if (option.label != "") {
        this.PPDBSelectLabel = option.label;
        this.dropzoneUpload = true;
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    onFileSelected(event) {
      //console.log(event[0]);
      this.selectedFile = event[0];
      this.onUpload();
    },
    onFileSelected2(event) {
      console.log(event.target.files[0]);
      /*
      this.selectedFile = event.target.files[0];
      this.onUpload();
      */
    },
    async onUpload() {
      let loading = this.overlayLoading();
      this.showButtonReadyToImport = false;
      // get param
      let id_ppdb = this.selects.id_ppdb;

      let url_upload = this.urlimportupload + "/" + id_ppdb;
      const fd = new FormData();
      fd.append("file_upload", this.selectedFile);
      fd.append("type", this.type);
      axios
        .post(url_upload, fd, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          onUploadProgress: (uploadEvent) => {
            this.uploadProgressFile =
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
            console.log("Upload Progress: " + this.uploadProgressFile);
          },
        })
        .then(
          (res) => {
            this.import_action_error_message = "";
            //console.log(res);
            if (res.data.status == "success") {
              this.tableImportPreview = true;
              this.formImportUpload = false;
              let scanningMessage =
                "<b>READY TO IMPORT:</b> <b style='color:green;'>" +
                res.data.count_complete_data +
                "</b>, <b>CANNOT IMPORT:</b> <b style='color:red;'>" +
                res.data.count_incomplete_data +
                "</b><br /><span class='text-muted'>Silahkan cek data pada preview tabel, selanjutnya klik tombol 'Import Data' untuk memasukkan data.</span>";
              Swal.fire("Scanning Success!", scanningMessage, "success");
              this.checkListSuccess = "Successfully uploaded..";
              loading.close();
              this.csv = res.data.csv;
              //console.log(res.data);

              console.log(res.data);

              this.datas = res.data.data;
              this.tableData = this.datas;

              this.showFileUploadForm = false;
              setTimeout(() => {
                this.showFileUploadForm = true;
                let count_complete_data = res.data.count_complete_data;
                if(count_complete_data != 0) {
                  this.showButtonReadyToImport = true;
                }
                this.scanningResult = scanningMessage;
              }, 1000);
            } else if (res.data.status == "error") {
              loading.close();
              console.log("error");
              Swal.fire("Error!", res.data.message, "error");
            }
          },
          (err) => {
            this.import_action_error_message = "";
            //console.log(err);
            Swal.fire("Error!", "Error upload!, " + err.data.message, "error");
            loading.close();
            this.showFileUploadForm = false;
            setTimeout(() => {
              this.showFileUploadForm = true;
            }, 1000);
          }
        );
    },
    async actionImportData() {
      try {
        let loading = this.overlayLoading();
        const response = await axios({
          method: "post",
          url: this.urlimportstore,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: {
            dataimport: this.tableData,
          },
        });

        this.import_action_error_message = response.data.error_message;

        if (response.data.status == "success") {
          Swal.fire("Good Job!", response.data.message, "success");
        } else if (response.data.status == "error") {
          Swal.fire("Error", response.data.message, "error");
        }

        loading.close();
      } catch (error) {
        console.log(error);
        this.displayError =
          "[" + error.response.status + "] ERROR! " + error.response.data;
        loading.close();
      }
    },
    openFormUpload() {
      this.tableImportPreview = false;
      this.formImportUpload = true;
    },
  },
};
</script>

<style>
</style>