<template>
  <div>
  </div>
</template>

<script>
export default {
    name: "Auth",
    data() {
        return {
            token: '',
        }
    },
    beforeCreate() {
        console.log('beforeCreated run');
    },  
    created() {
        console.log('create run');
        const token = localStorage.getItem("token");
        this.token = token;
        if(!token) {
            this.$router.push({'path':'/login'});
        }
    },
}
</script>

<style>

</style>