<template>
  <div>
    <div class="row">
      <div
        v-for="(formGenerator, index) in formGenerators"
        :key="formGenerator.id"
        :class="rowCol"
      >
        <div :data-index="index">
          <!-- Start Title -->
          <div v-if="fieldtitle[index] !== undefined">
            <h3 class="text-primary">#{{ fieldtitle[index].title }}</h3>
          </div>
          <!-- End Title -->

          <!-- Start Input Form -->
          <div v-if="formGenerator.input_type == 'hidden'">
            <input
              type="hidden"
              class="form-control"
              v-model="forminit[index]"
            />
          </div>

          <div v-if="formGenerator.input_type == 'text'">
            <div :class="formGroupRow">
              <label :class="formGroupLabel">{{ formGenerator.label }}</label>
              <div :class="formGroupInput">
                <input
                  type="text"
                  class="form-control"
                  v-model="forminit[index]"
                />
              </div>
            </div>
          </div>

          <div v-if="formGenerator.input_type == 'number'">
            <div :class="formGroupRow">
              <label :class="formGroupLabel">{{ formGenerator.label }}</label>
              <div :class="formGroupInput">
                <input
                  type="number"
                  class="form-control"
                  v-model="forminit[index]"
                />
              </div>
            </div>
          </div>

          <div v-if="formGenerator.input_type == 'textarea'">
            <div :class="formGroupRow">
              <label :class="formGroupLabel">{{ formGenerator.label }}</label>
              <div :class="formGroupInput">
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="forminit[index]"
                ></textarea>
              </div>
            </div>
          </div>

          <div v-if="formGenerator.input_type == 'options'">
            <div :class="formGroupRow">
              <label :class="formGroupLabel">{{ formGenerator.label }}</label>
              <div :class="formGroupInput">
                <select v-model="forminit[index]" class="form-control">
                  <option
                    v-for="select in formGenerator.selects"
                    v-bind:key="select.value"
                    v-bind:value="select.value"
                  >
                    {{ select.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="formGenerator.input_type == 'datepicker'">
            <div :class="formGroupRow">
              <label :class="formGroupLabel">{{ formGenerator.label }}</label>
              <div :class="formGroupInput">
                <div class="input-group">
                  <flat-pickr
                    v-model="forminit[index]"
                    class="form-control"
                    placeholder="Select date"
                  ></flat-pickr>
                  <div class="input-group-append">
                    <button
                      class="btn btn-default"
                      type="button"
                      title="Toggle"
                      data-toggle
                    >
                      <i class="fa fa-calendar">
                        <span aria-hidden="true" class="sr-only">Toggle</span>
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Input Form -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "GenInput",
  components: {
    flatPickr,
  },
  props: {
    formtype: { required: false, type: String },
    forminit: { required: true, type: Object },
    fieldspecial: { required: true, type: Object },
    fieldtitle: { required: true, type: Object },
    row: { type: String },
  },
  data() {
    return {
      formGenerators: [],
      formGroupRow: "form-group row",
      formGroupLabel: "col-md-2 col-form-label form-control-label",
      formGroupInput: "col-md-10",
      rowCol: "col-md-12",
    };
  },
  created() {
    this.createFormGenerator();
  },
  methods: {
    createFormGenerator() {
      if (this.formtype == "default") {
        this.formGroupRow = "form-group";
        this.formGroupLabel = "col-md-12 col-form-label form-control-label";
        this.formGroupInput = "col-md-12";
      }
      if(this.row != undefined) {
        this.rowCol = this.row
      }
      let formInit = this.forminit;
      let formGeneratorInit = [];

      for (let x in formInit) {
        let replaceUS = x.replace(/_/g, " ");
        let labelWords = this.ucwords(replaceUS);

        if (this.fieldspecial[x] !== undefined) {
          if (this.fieldspecial[x].input_type == "options") {
            formGeneratorInit[x] = {
              input_type: this.fieldspecial[x].input_type,
              label: this.fieldspecial[x].label,
              selects: this.fieldspecial[x].selects,
            };
          } else {
            formGeneratorInit[x] = {
              input_type: this.fieldspecial[x].input_type,
              label: this.fieldspecial[x].label,
            };
          }
        } else {
          formGeneratorInit[x] = {
            input_type: "text",
            label: labelWords,
          };
        }
      }
      this.formGenerators = Object.assign({}, formGeneratorInit);
      //console.log(this.formGenerators);
    },
    ucwords(str) {
      return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
        return $1.toUpperCase();
      });
    },
  },
};
</script>

<style>
</style>