<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h3>{{ label }} ({{ type.replace("_", " ") }})</h3>
      </div>
      <div class="card-body">
        <p class="text-muted">{{ formatinfo }}<br />{{ sizeinfo }}</p>
        <div v-if="loadingFile" class="mt-3">Loading file...</div>
        <div v-if="anyFile">
          <div v-if="accept == 'application/pdf'">
            <i class="fa fa-file-pdf fa-5x text-danger"></i><br /><a
              :href="urlFile"
              >{{ filename }}</a
            >
          </div>
          <div v-if="accept == 'image/jpeg, image/png, image/gif'">
            <img
              :src="urlFile"
              class="img-thumbnail img-fluid"
              style="height: 100px"
              :alt="type"
            />
          </div>
        </div>
        <div v-if="noFile" class="text-center">
          <div class="card">
            <div class="card-body">
              <br />
              <i class="fas fa-camera fa-5x text-muted"></i>
              <br /><br />
            </div>
          </div>
        </div>
        <div class="form-group mt-3">
          <input
            type="file"
            @change="onFileSelected"
            class="form-control-file"
            :accept="accept"
          />
        </div>
        
        &nbsp;&nbsp;<small class="text-muted"
          ><i
            >{{ uploadProgressFile }}
            <span class="text-success">{{ checkListSuccess }}</span></i
          ></small
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "GenUpload",
  props: {
    label: { required: true, type: String },
    formatinfo: { required: true, type: String },
    sizeinfo: { required: true, type: String },
    type: { required: true, type: String },
    accept: { required: true, type: String },
    url_upload: { required: true, type: String },
    url_read: { required: true, type: String },
    pathfolder: { required: true, type: String },
  },
  data() {
    return {
      token: "",
      dataAuth: "",
      selectedFile: null,
      loadingFile: false,
      anyFile: false,
      noFile: false,
      uploadProgressFile: "",
      filename: "",
      urlFile: "",
      basePath: axios.defaults.baseURL,
      basePathFolderImage: "/uploads",
      mainProps: { width: 300, class: "m1" },
      checkListSuccess: "",
    };
  },
  created() {
    this.getDefaultVar();
    this.getDataUpload();
  },
  methods: {
    getDefaultVar() {
      this.token = localStorage.getItem("token");
      if (this.accept === undefined) {
        this.accept = "image/jpeg, image/png, image/gif";
      }
    },
    async getDataUpload() {
      this.loadingFile = true;
      console.log('GetDataUpload: '+this.url_read);
      
      try {
        const response = await axios({
          method: "get",
          url: this.url_read,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //console.log(response.data.fileread[this.type]);
        let fileread = response.data.fileread[this.type];
        let typeRegDoc = this.type.toLowerCase();

        if (fileread != undefined) {
          this.loadingFile = false;
          this.anyFile = true;
          this.noFile = false;

          let filename = fileread;
          this.filename = filename;
          this.urlFile =
            this.basePath +
            this.basePathFolderImage +
            this.pathfolder +
            "/" +
            filename;
          console.log("file tatatertib " + " => " + this.urlFile);
        } else {
          this.loadingFile = false;
          this.anyFile = false;
          this.noFile = true;
        }
      } catch (error) {
        console.log(error);
        this.loadingFile = false;
      }
      
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      //console.log(this.selectedFile);
      this.onUpload();
    },
    onUpload() {
      const fd = new FormData();
      fd.append("file_upload", this.selectedFile);
      fd.append("type", this.type);
      axios
        .post(this.url_upload, fd, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          onUploadProgress: (uploadEvent) => {
            this.uploadProgressFile =
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
            console.log("Upload Progress: " + this.uploadProgressFile);
          },
        })
        .then(
          (res) => {
            console.log(res);
            Swal.fire("Good job!", "Data berhasil disimpan!", "success");
            this.checkListSuccess = "Successfully uploaded..";
            this.getDataUpload();
            //console.log(fd);
          },
          (err) => {
            console.log(err);
            Swal.fire("Error!", "Error upload!, " + err.message, "error");
          }
        );
    },
  },
};
</script>

<style>
</style>