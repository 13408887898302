<template>
  <div>
    <div
      class="col-12 justify-content-center justify-content-sm-between flex-wrap"
    >
      <Form @submit.prevent="onSubmit" :validation-schema="schema">
        <GenInput
          :forminit="form"
          :fieldspecial="fieldspecial"
          :fieldtitle="fieldtitle"
        />
        <br />
        <div class="text-right">
          <base-button type="default" :loading="isLoading" native-type="submit"
            >Simpan Data</base-button
          >
        </div>
      </Form>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <GenUpload
            label="Upload File"
            formatinfo="Upload file dokumen berformat JPG/PNG"
            sizeinfo="Maksimum ukuran 2MB."
            type="logo"
            accept="image/jpeg, image/png, image/gif"
            url_upload="api/settingapps/logo/store"
            url_read="api/settingapps/logo/read"
            pathfolder="/settingapps"
          ></GenUpload>
        </div>
        <div class="col-md-6">
          <GenUpload
            label="Upload File"
            formatinfo="Upload file dokumen berformat JPG/PNG"
            sizeinfo="Maksimum ukuran 2MB."
            type="logo_admin"
            accept="image/jpeg, image/png, image/gif"
            url_upload="api/settingapps/logo_admin/store"
            url_read="api/settingapps/logo_admin/read"
            pathfolder="/settingapps"
          ></GenUpload>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <GenUpload
            label="Upload File"
            formatinfo="Upload file dokumen berformat PDF"
            sizeinfo="Maksimum ukuran 2MB."
            type="financeruleinfo"
            accept="application/pdf"
            url_upload="api/settingapps/financeruleinfo/store"
            url_read="api/settingapps/financeruleinfo/read"
            pathfolder="/settingapps"
          ></GenUpload>
        </div>
        <div class="col-md-6">
          <GenUpload
            label="Upload File"
            formatinfo="Upload file dokumen berformat PDF"
            sizeinfo="Maksimum ukuran 2MB."
            type="schoolruleinfo"
            accept="application/pdf"
            url_upload="api/settingapps/schoolruleinfo/store"
            url_read="api/settingapps/schoolruleinfo/read"
            pathfolder="/settingapps"
          ></GenUpload>
        </div>
        <div class="col-md-6">
          <GenUpload
            label="Upload File"
            formatinfo="Upload file dokumen berformat JPG/PNG"
            sizeinfo="Maksimum ukuran 2MB."
            type="barcode"
            accept="image/jpeg, image/png, image/gif"
            url_upload="api/settingapps/barcode/store"
            url_read="api/settingapps/barcode/read"
            pathfolder="/settingapps"
          ></GenUpload>
        </div>
        <div class="col-md-6">
          <GenUpload
            label="Upload File"
            formatinfo="Upload file dokumen berformat JPG/PNG"
            sizeinfo="Maksimum ukuran 2MB."
            type="barcode"
            accept="image/jpeg, image/png, image/gif"
            url_upload="api/settingapps/bgimage_login/store"
            url_read="api/settingapps/bgimage_login/read"
            pathfolder="/settingapps"
          ></GenUpload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import GenInput from "@/components/Generate/GenInput";
import GenUpload from "@/components/Generate/GenUpload.vue";
export default {
  name: "SettingAppForm.vue",
  components: {
    GenInput,
    GenUpload,
  },
  data() {
    return {
      datas: [],
      formGenerators: [],
      form: {
        id: "",
        name: "",
        address: "",
        sign_area: "",
        tagline: "",
        phone: "",
        email: "",
        psb_link: "",
        akademik_link: "",
        ppdb_title: "",
        ppdb_subtitle: "",
        facebook: "",
        instagram: "",
        twitter: "",
        youtube: "",
        wa_number: "",
        wa_token: "",
        finacc_number: "",
        finacc_name: "",
        finacc_bankname: "",
        leader: "",
        note: "",
      },
      fieldspecial: {
        id: {
          input_type: "hidden",
          label: "",
        },
        address: {
          input_type: "textarea",
          label: "Alamat Sekolah",
        },
        sign_area: {
          input_type: "text",
          label: "Area Lokasi (untuk Keperluan Tanda Tangan)",
        },
        note: {
          input_type: "textarea",
          label: "Catatan",
        },
        phone: {
          input_type: "text",
          label: "Telepon",
        },
        wa_number: {
          input_type: "text",
          label: "Whatsapp Nomor",
        },
        wa_token: {
          input_type: "text",
          label: "Whatsapp Token",
        },
        finacc_number: {
          input_type: "text",
          label: "Nomor Rekening Akun Bank",
        },
        finacc_name: {
          input_type: "text",
          label: "Nama Rekening Akun Bank",
        },
        finacc_bankname: {
          input_type: "text",
          label: "Nama Bank",
        },
      },
      fieldtitle: {
        name: {
          title: "Data Umum",
        },
        phone: {
          title: "Data Kontak",
        },
        psb_link: {
          title: "Link Aplikasi Pendukung",
        },
        facebook: {
          title: "Media Sosial",
        },
        wa_number: {
          title: "Whatsapp Gateway",
        },
        finacc_number: {
          title: "Rekening Bank Sekolah",
        },
        leader: {
          title: "Misc",
        },
      },
      isLoading: false,
    };
  },
  created() {
    this.getSettingApps();
  },
  methods: {
    async getSettingApps() {
      try {
        const response = await axios.get("/api/settingapps/read", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data.data;
        for (let x in this.datas) {
          this.form[x] = this.datas[x];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const response = await axios({
          method: "post",
          url: "/api/settingapps/store",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });

        Swal.fire("Good job!", "Data berhasil disimpan!", "success");
        console.log(response.data);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>