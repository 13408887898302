<template>
  <div>
    <div
      class="
        col-12
        d-flex
        justify-content-center justify-content-sm-between
        flex-wrap
      "
    >
      <div id="profile-photo">
        <table border="0">
          <tr>
            <td>
              <img
                :src="profile.url_photo"
                class="img-thumbnail img-fluid"
                width="100"
              />
            </td>
            <td width="10px"></td>
            <td>
              <big
                ><b
                  >{{ profile.fullname }}
                  <small>({{ profile.noreg }})</small></b
                ></big
              ><br />
              <p>{{ profile.ppdb_desc }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-12" style="padding: 20px 40px 20px 40px">
      <br />
      <div id="profile-information">
        <div class="row">
          <div class="col-md-3">
            <h3>#IDENTITAS SISWA</h3>
            <hr style="margin-top: 0px" />
            <p style="line-height: 2; font-size: 14px">
              <b>Nama Lengkap:</b> {{ profile.fullname }}<br />
              <b>Nama Panggilan:</b> {{ profile.nickname }}<br />
              <b>Jenis Kelamin:</b> {{ profile.gender }}<br />
              <b>Tempat Tanggal Lahir:</b> {{ profile.birthplace }},
              {{ profile.birthdate }}<br />
              <b>Kewarganegaraan:</b> {{ profile.wni }}<br />
              <b>Bahasa Sehari-hari:</b> {{ profile.lang }}<br />
              <b>Agama:</b> {{ profile.religion }}<br />
              <b>Anak ke:</b> {{ profile.childno }}<br />
              <b>Jumlah Saudara Kandung:</b> {{ profile.sibling }}<br />
              <b>Jumlah Saudara Tiri:</b> {{ profile.stepsibling }}<br />
              <b>NIK:</b> {{ profile.nik }}<br />
              <b>NIKK:</b> {{ profile.nikk }}<br />
            </p>
            <br />
            <h3>#DATA TEMPAT TINGGAL</h3>
            <hr style="margin-top: 0px" />
            <p style="line-height: 2; font-size: 14px">
              <b>Tinggal dengan: </b>
              <span v-if="livinginfo.id_family == null"> Ibu & Ayah</span>
              <span v-else>
                {{ livinginfo.livinginfo_fullname }}<br />
                <b>Hubungan:</b> {{ livinginfo.relation_desc }}
              </span>
              <br />
              <b>Alamat:</b> {{ livinginfo.address }}<br />
              <b>Nomor Rumah:</b> {{ livinginfo.home_number }}<br />
              <b>RT/RW:</b> {{ livinginfo.rt_number }}/{{ livinginfo.rw_number
              }}<br />
              <b>Kodepos:</b> {{ livinginfo.postalcode }}<br />
              <b>Jarak Rumah:</b>
              {{ livinginfo.distance_km }} km<br />
              <b>Waktu Tempuh:</b>
              {{ livinginfo.distance_hour }} jam
              {{ livinginfo.distance_minute }} menit<br />
            </p>
            <br />
            <h3>#KETERANGAN SEKOLAH ASAL</h3>
            <hr style="margin-top: 0px" />
            <p style="line-height: 2; font-size: 14px">
              <b>Asal Sekolah:</b> {{ eduinfo.school_name }}
              <br />
              <b>NISN:</b> {{ eduinfo.nisn }} <br /><br />
              Alasan kepindahan jika ada<br />
              <b>Kelas yang Ditinggalkan:</b>
              {{ eduinfo.abandoned_classes }}
              <br />
              <b>Alasan Kepindahan: </b>
              {{ eduinfo.reason_for_the_move }}
              <br />
            </p>
          </div>
          <div class="col-md-4">
            <h3>#DATA ORANG TUA</h3>
            <hr style="margin-top: 0px" />
            <p
              style="line-height: 2; font-size: 14px"
              v-for="family in familyinfo"
              v-bind:key="family.id_family"
            >
              <b>Hubungan:</b> {{ family["relation_desc"] }}<br />
              <b>Nama Lengkap:</b> {{ family["fullname"] }}<br />
              <b>Tempat Tanggal Lahir:</b>
              {{ family["birthplace"] }}, {{ family["birthdate"] }}<br />
              <b>Alamat:</b> {{ family["address"] }}<br />
              <b>Kewarganegaraan:</b> {{ family["wni"] }}<br />
              <b>Agama:</b> {{ family["religion"] }}<br />
              <b>Telepon:</b> {{ family["cellphone"] }}<br />
              <b>Email:</b> {{ family["email"] }}<br />
              <b>Pendidikan:</b> {{ family["edulevel"] }}<br />
            </p>
            <br />
            <h3>#KETERANGAN KESEHATAN</h3>
            <hr style="margin-top: 0px" />
            <p style="line-height: 2; font-size: 14px">
              <!--<b>Selera Makan:</b> {{ medicalinfo.apetite }}<br />-->
              <b>Golongan Darah:</b> {{ medicalinfo.blood_grp }}<br />
              <b>Riwayat Kelahiran:</b>
              {{ medicalinfo.birth_histry }}<br />
              <b>Berat Badan:</b> {{ medicalinfo.weight }}<br />
              <b>Tinggi Badan:</b> {{ medicalinfo.height }}<br />
              <b>Kelainan Jasmani:</b>
              {{ medicalinfo.disability }}<br />
              <b>Riwayat Operasi:</b>
              {{ medicalinfo.surgical_histry }}<br />
              <b>Riwayat Penyakit:</b>
              {{ medicalinfo.desease_histry }}<br />
              <b>Alergi Makanan:</b>
              {{ medicalinfo.food_allergy }}<br />
              <b>Alergi Obat:</b> {{ medicalinfo.medic_allergy }}<br />
              <b>Riwayat Rumah Sakit:</b>
              {{ medicalinfo.hospital_name }}<br />
              <b>Nomor Rekam Medis:</b> {{ medicalinfo.noreg_rs }}<br />
              <b>Riwayat Terapi:</b>
              {{ medicalinfo.therapy_histry }}<br />
            </p>
          </div>
          <div class="col-md-5">
            <h3>#DOKUMEN UPLOAD</h3>
            <hr style="margin-top: 0px" />
            <div class="row">
              <div class="col-md-6">
                <div v-if="regdocsupport.url_photo !== ''">
                  <img
                    width="300"
                    :src="regdocsupport.url_photo"
                    class="img-fluid img-thumbnail"
                  />
                  <p>
                    <a target="_blank" :href="regdocsupport.url_photo"
                      >Dokumen PHOTO</a
                    >
                  </p>
                </div>
                <div v-else><p>PHOTO: No Dokumen Upload</p></div>
                <br />
                <div v-if="regdocsupport.url_akta !== ''">
                  <img
                    width="300"
                    :src="regdocsupport.url_akta"
                    class="img-fluid img-thumbnail"
                  />
                  <p>
                    <a target="_blank" :href="regdocsupport.url_akta"
                      >Dokumen AKTA</a
                    >
                  </p>
                </div>
                <div v-else><p>AKTA: No Dokumen Upload</p></div>
                <br />
              </div>
              <div class="col-md-6">
                <div v-if="regdocsupport.url_kk !== ''">
                  <img
                    width="300"
                    :src="regdocsupport.url_kk"
                    class="img-fluid img-thumbnail"
                  />
                  <p>
                    <a target="_blank" :href="regdocsupport.url_kk"
                      >Dokumen KK</a
                    >
                  </p>
                </div>
                <div v-else><p>KK: No Dokumen Upload</p></div>
                <br />
                <div v-if="regdocsupport.url_otm !== ''">
                  <img
                    width="300"
                    :src="regdocsupport.url_otm"
                    class="img-fluid img-thumbnail"
                  />
                  <p>
                    <a target="_blank" :href="regdocsupport.url_otm"
                      >Dokumen OTM</a
                    >
                  </p>
                </div>
                <div v-else><p>OTM: No Dokumen Upload</p></div>
                <br />
                <div v-if="regdocsupport.url_raport !== ''">
                  <i class="fa fa-file-pdf fa-5x text-danger"></i><br />
                  <p>
                    <a target="_blank" :href="regdocsupport.url_raport"
                      >Dokumen RAPORT</a
                    >
                  </p>
                </div>
                <div v-else><p>RAPORT: No Dokumen Upload</p></div>
              </div>
            </div>
            <hr />
            <div class="text-left" style="line-height: 2">
              <div style="margin-bottom: 10px">
                <div style="margin-bottom: 10px">
                  <base-button
                    type="default"
                    @click.prevent="handleParentAgreementInfo"
                    ><i class="ni ni-cloud-download-95"></i>
                    {{ parentAgreementInfoButtonLabel }}</base-button
                  >
                </div>
                <div>
                  <base-button
                    type="default"
                    @click.prevent="handlePrintProfileRegister"
                    ><i class="ni ni-cloud-download-95"></i>
                    {{ printProfileRegisterButtonLabel }}</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RegProfileInformation",
  props: {
    profile: { required: true, type: Object },
  },
  data() {
    return {
      eduinfo: [],
      familyinfo: [],
      livinginfo: [],
      medicalinfo: [],
      regdocsupport: [],
      id_reg: "",
      basePath: axios.defaults.baseURL,
      schoolRuleInfoButtonLabel: "Tata Tertib Sekolah",
      financeRuleInfoButtonLabel: "Tata Tertib Keuangan",
      parentAgreementInfoButtonLabel: "Pernyataan Orang Tua",
      printProofRegisterButtonLabel: "Print Bukti Pendaftaran",
      printProfileRegisterButtonLabel: "Print Biodata Pendaftar",
    };
  },
  created() {
    this.getEduInfo(this.profile);
    this.getFamilyInfo(this.profile);
    this.getLivingInfo(this.profile);
    this.getMedicalInfo(this.profile);
    this.getRegDocSupportInfo(this.profile);
  },
  methods: {
    async getEduInfo(profile) {
      try {
        const response = await axios.get("/api/eduinfo/" + profile.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.eduinfo = response.data;
      } catch (error) {
        //console.log(error);
      }
    },
    async getFamilyInfo(profile) {
      try {
        const response = await axios.get("/api/familyinfo/" + profile.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.familyinfo = response.data;
        console.log(this.familyinfo);
      } catch (error) {
        //console.log(error);
      }
    },
    async getLivingInfo(profile) {
      try {
        const response = await axios.get("/api/livinginfo/" + profile.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.livinginfo = response.data;
      } catch (error) {
        //console.log(error);
      }
    },
    async getMedicalInfo(profile) {
      try {
        const response = await axios.get("/api/medicalinfo/" + profile.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.medicalinfo = response.data;
      } catch (error) {
        //console.log(error);
      }
    },
    async getRegDocSupportInfo(profile) {
      try {
        const response = await axios.get(
          "/api/regdocsupportinfo/" + profile.id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.regdocsupport = response.data;
      } catch (error) {
        //console.log(error);
      }
    },
    async handleSchoolRuleInfo() {
      this.schoolRuleInfoButtonLabel = "Loading...";
      try {
        const response = await axios({
          method: "get",
          url: "/api/settingapps/schoolruleinfo/read",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let resdata = response.data;
        let urlPDF = resdata["fileurl"];
        let randomNumber = Date.now();
        window.open(urlPDF + "?rand=" + randomNumber);
        this.schoolRuleInfoButtonLabel = "Tata Tertib Sekolah";
      } catch (error) {
        console.log(error);
        this.schoolRuleInfoButtonLabel = "Tata Tertib Sekolah";
      }
    },
    async handleFinanceRuleInfo() {
      this.financeRuleInfoButtonLabel = "Loading...";
      try {
        const response = await axios({
          method: "get",
          url: "/api/settingapps/financeruleinfo/read",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let resdata = response.data;
        let urlPDF = resdata["fileurl"];
        let randomNumber = Date.now();
        window.open(urlPDF + "?rand=" + randomNumber);
        this.financeRuleInfoButtonLabel = "Tata Tertib Keuangan";
      } catch (error) {
        console.log(error);
        this.financeRuleInfoButtonLabel = "Tata Tertib Keuangan";
      }
    },
    async handleParentAgreementInfo() {
      this.parentAgreementInfoButtonLabel = "Loading...";

      let random = Math.random().toString(16).slice(2);
      let urlparentagreement =
        this.basePath +
        "/api/reg/parentagreement/" +
        this.profile.noreg +
        "/" +
        random;
      window.open(urlparentagreement);

      this.parentAgreementInfoButtonLabel = "Pernyataan Orang Tua";
    },
    async handlePrintProfileRegister() {
      this.printProfileRegisterButtonLabel = "Loading...";

      let random = Math.random().toString(16).slice(2);
      let urlprintprofileregister =
        this.basePath +
        "/api/reg/profileresume/" +
        this.profile.noreg +
        "/" +
        random;
      window.open(urlprintprofileregister);
      this.printProfileRegisterButtonLabel = "Print Biodata Pendaftaran";
    },
  },
};
</script>

<style>
</style>